import { Row, Col, Input, Space, Divider, Button } from "antd";
import { useState } from "react";

const FillModalContent = ({ orderData, sku, onOk, onCancel }) => {
  const { id, sku_id, total_quantity } = orderData;

  const { quantity, pending } = orderData?.items[0] || {};

  const [fillQuantity, setFillQuantity] = useState(pending);

  const okHandler = () => {
    if (fillQuantity > pending || fillQuantity > quantity) {
      window.alert(
        "Fill quantity cannot be greater than the total or pending quantity"
      );
      setFillQuantity(pending);
      return null;
    } else if (parseInt(fillQuantity) === 0) {
      window.alert("Fill quantity cannot be zero");
      return null;
    }

    let _finalObj = {
      mo_id: id,
      sku_id: sku_id,
      fill_quantity: fillQuantity,
    };

    onOk(_finalObj);
  };

  return (
    <div>
      <div>
        <Row>
          <Col>
            <p className="bold">#ID</p>
            <div>{id}</div>
          </Col>
          <Col span={4} offset={1}>
            <p className="bold">SKU Name</p>
            <div>{sku.sku}</div>
          </Col>

          <Col span={3} offset={1}>
            <p className="bold">Total Quantity</p>
            <div>{quantity}</div>
          </Col>

          <Col span={4}>
            <p className="bold">Current Pending Quantity</p>
            <div>{pending}</div>
          </Col>

          <Col span={4} offset={1}>
            <p className="bold">New Pending Quantity</p>
            <div>{pending - fillQuantity} Pc</div>
          </Col>

          <Col span={3} offset={1}>
            <p className="bold">Fill Quantity</p>
            <div>
              <Input
                type="number"
                max={total_quantity}
                min={0}
                value={fillQuantity}
                suffix="Pc"
                onChange={(e) => setFillQuantity(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <Space />

        <Divider />
        <div className="flex justify-end">
          <Button className="mr-0.5" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={okHandler}>
            Fill Order
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FillModalContent;
