import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

/**
 * Will Route towards the desired component if the user is logged in, and towards the login page if the user is not logged in.
 * @param {{JSX.Element}} component - The Component which we want to render on a particular route (manually provided as a prop)
 * @param {{JSX.Element}} auth - The `auth` state obtained from the redux store
 * @returns The Desired Component Screen or the Login Screen
 */
const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth.isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(PrivateRoute);
