import { REMOVE_USER, SET_USER } from "../actions/actionTypes";

/**
 * Modifies the `auth` state
 * @param {Object} state - The current state object (before updation)
 * @param {Object} action - The action object as dispatched
 * @returns New state, which gets assigned to the `auth` key
 */
export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_USER:
      return {
        ...state,
        user: {},
        isLoggedIn: false
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload.data,
        isLoggedIn: true
      };
    default:
      return state;
  }
};

export default authReducer;
