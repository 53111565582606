import {
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  Button,
  Table,
  Divider,
  Spin,
  message,
  Popconfirm,
} from "antd";
import { DATE_TIME_FORMAT } from "../../../assets/constants";

import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import getAddress from "../../../common/functions/getAddress";

import * as yup from "yup";
import { useFormik } from "formik";
import usePrevious from "../../../common/hooks/usePrevious";
import { roundValue } from "../../../common/functions/roundValue";

const filterOption = (input, option) =>
  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const EMPTY_ITEMS_FORMAT = {
  id: "",
  material_id: "",
  material_name: "",
  quantity: "",
  rate: "",
  tax: "",
  amount: "",
};

const getValidationSchema = () => {
  return yup.object().shape({
    location_id: yup.number().required(),
    order_status: yup.string().required(),
    supplier_id: yup.number().nullable().required(),
    created_date: yup.string().required(),
    due_date: yup.string().required(),
    payment_terms: yup.string().required(),
    delivery_terms: yup.string(),
    receipt_status: yup.string().required(),
    track_code: yup.string(),
    transport_cost: yup.number().nullable(),
    total_amount: yup.number().required(),
    transporter: yup.number().nullable(),
    items: yup
      .array()
      .of(
        yup.object().shape({
          material_id: yup.number().required("Material cannot be empty"),
          quantity: yup.number().required("Quantity used cannot be empty"),
          rate: yup.number().required("Rate used cannot be empty"),
        })
      )
      .required("Please add atleast one Item")
      .test((items) =>
        !items.length ||
        items.some(
          ({ id, item_name, quantity }) => !id || !item_name || !quantity
        )
          ? "Incomplete Details of Item"
          : ""
      ),
  });
};

const CreateOrUpdate = ({
  po,
  suppliers,
  transporters,
  materials,
  onOk,
  onCancel,
  locId,
  paymentTerms,
}) => {
  const getInitialValues = () => {
    if (po?.id) {
      const {
        location_id,
        order_status,
        supplier_id,
        created_date,
        due_date,
        payment_terms,
        delivery_terms,
        receipt_status,
        track_code,
        transport_cost,
        total_amount,
        comments,
        items,
      } = po;

      const supplier_address = po.supplier_address?.id;

      const transporter = po?.transporter?.id;

      return {
        location_id,
        order_status,
        supplier_id,
        created_date,
        due_date,
        payment_terms,
        delivery_terms,
        receipt_status,
        track_code,
        transport_cost,
        total_amount,
        comments,
        items,
        supplier_address,
        transporter,
      };
    }
    return {
      order_status: "Approved",
      location_id: locId,
      supplier_id: null,
      created_date: moment().toISOString(),
      due_date: moment().toISOString(),
      payment_terms: "",
      delivery_terms: "",
      receipt_status: "Not Received",
      track_code: "",
      transport_cost: "",
      transporter: null,
      total_amount: 0,
      comments: "",
      items: [EMPTY_ITEMS_FORMAT],
      supplier_address: null,
      supplier_address_options: [],
    };
  };

  const handleCuClick = async () => {
    let _mandatoryFields = {
      created_date: values.created_date,
      due_date: values.due_date,
      location_id: values.location_id,
      supplier_id: values.supplier_id,
      supplier_address: values.supplier_address,
      total_amount: values.total_amount,
      order_status: values.order_status,
    };

    console.log(_mandatoryFields, "@@@@@@ 361");

    let error = "";
    if (Object.values(_mandatoryFields).some((el) => !el)) {
      error = "Enter complete details!";
    } else if (!values.items.length) {
      error = "You must enter at least one material item!";
    } else if (
      values.items.some(
        ({ material_id, material_name, tax, quantity, uom }) => {
          if ([material_id, material_name, tax, quantity, uom].includes(""))
            return true;
          return false;
        }
      )
    ) {
      error = "Incomplete Material Item details added!";
    }

    if (error) {
      message.error(`${error}`);
      return;
    }

    let finalObj = {
      ..._mandatoryFields,
      comments: values.comments,
      order_status: values.order_status,
      payment_terms: values.payment_terms,
      delivery_terms: values.delivery_terms,
      receipt_status: values.receipt_status,
      transporter: values.transporter,
      track_code: values.track_code,
      transport_cost: values.transport_cost || 0,
      items: values.items,
      total_amount: values.total_amount,
    };

    if (po.id) finalObj["id"] = po.id;

    // perform checks on the final object
    // return;

    try {
      setLoading(true);
      const { data } = await axios.post(
        `/api/purchase/po/${po.id ? "edit" : "create"}`,
        finalObj
      );

      setLoading(false);
      onOk();
      onCancel();
    } catch (error) {
      setLoading(false);
      window.alert(error.message);
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getValidationSchema(),
    onSubmit: handleCuClick,
  });

  const {
    values,
    setFieldValue,
    handleChange,
    setFieldTouched,
    handleBlur,
    touched,
    errors,
  } = formik;

  const [loading, setLoading] = useState(false);
  const prevSupplier = usePrevious(values.supplier_id);

  useEffect(() => {
    let _addresses = suppliers?.find(
      (el) => el?.id === values.supplier_id
    )?.addresses;
    setFieldValue("supplier_address_options", _addresses);
    if (!prevSupplier) {
      if (po?.id) {
        setFieldValue("supplier_address", po?.supplier_address?.id);
      } else setFieldValue("supplier_address", _addresses?.[0]?.id);
    } else {
      setFieldValue("supplier_address", _addresses?.[0]?.id);
    }
    //eslint-disable-next-line
  }, [values.supplier_id]);

  useEffect(() => {
    setFieldValue(
      "total_amount",
      parseFloat(
        values.items.reduce((a, b) => {
          return a + b.amount;
        }, 0)
      ) + parseFloat(values.transport_cost || 0)
    );
    //eslint-disable-next-line
  }, [values.items, values.transport_cost]);

  const createUpdateCols = [
    {
      title: "Material ID",
      render: (value, record) => {
        return (
          <Input style={{ width: 50 }} value={record.material_id} disabled />
        );
      },
    },
    {
      title: "Material Name",
      render: (value, record, index) => {
        return (
          <Select
            showSearch
            filterOption={filterOption}
            style={{ width: 300 }}
            value={
              record.name ||
              materials?.find(
                (el) => el?.id === values.items[index]?.material_id
              )?.name
            }
            onChange={(matId) => {
              let _temp = [...values.items];
              const { tax, uom, name } = materials.find((m) => m.id === matId);
              _temp[index] = {
                material_id: matId,
                material_name: name,
                tax,
                quantity: _temp[index].quantity || 0,
                uom,
              };
              setFieldValue("items", _temp);
            }}
          >
            {materials.map((m) => {
              return (
                <Select.Option key={m.id} value={m.id}>
                  {m.name}
                </Select.Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "Quantity",
      // dataIndex: "quantity",
      render: (value, record, index) => {
        return (
          <Input
            // type="number"
            style={{ width: 80 }}
            value={record.quantity}
            suffix={record.uom}
            onChange={({ target: { value } }) => {
              let _temp = [...values.items];
              _temp[index] = {
                ..._temp[index], // or ...record
                quantity: parseInt(value) || 0,
                amount: !record.rate
                  ? 0
                  : parseInt(value) * record.rate * (1 + record.tax / 100),
              };
              setFieldValue("items", _temp);
            }}
          />
        );
      },
    },
    {
      title: "Rate per UoM",

      render: (value, record, index) => {
        return (
          <Input
            // type="number"
            style={{ width: 100 }}
            suffix="INR"
            value={record.rate}
            onChange={({ target: { value } }) => {
              let _temp = [...values.items];
              _temp[index] = {
                ..._temp[index], // or ...record
                rate: value || 0,
                amount: !record.quantity
                  ? 0
                  : record.quantity *
                    parseFloat(value) *
                    (1 + record.tax / 100),
              };
              setFieldValue("items", _temp);
            }}
          />
        );
      },
    },
    {
      title: "Tax",
      render: (value, record, index) => {
        return (
          <Input
            // type="number"
            value={record.tax}
            onChange={({ target: { value } }) => {
              let _temp = [...values.items];
              _temp[index] = {
                ..._temp[index], // or ...record
                tax: value || 0,
                amount: !record.quantity
                  ? 0
                  : record.quantity *
                    parseFloat(record.rate) *
                    (1 + parseFloat(value || 0) / 100),
              };
              setFieldValue("items", _temp);
            }}
            style={{ width: 70 }}
            suffix="%"
          />
        );
      },
    },

    {
      title: "Amount",
      render: (value, record) => {
        return (
          <Input
            suffix="INR"
            style={{ width: 150 }}
            value={
              !record.quantity || !record.rate
                ? 0
                : roundValue(
                    record.quantity * record.rate * (1 + record.tax / 100)
                  )
            }
            disabled
          />
        );
      },
    },
    {
      title: "Remove",
      render: (value, record) => {
        return (
          <Button type="primary" onClick={() => removeMaterial(record.id)}>
            X
          </Button>
        );
      },
    },
  ];

  const handleDeleteClick = async () => {
    // return;
    try {
      setLoading(true);
      const { data } = await axios.delete(`/api/purchase/po/${po.id}`);
      setLoading(false);
      onOk();
      onCancel();
    } catch (error) {
      setLoading(false);
      window.alert(error.message);
    }
  };

  const addMaterial = () => {
    let maxId = 0;
    if (values.items.length) {
      maxId = values.items.reduce(
        (max, p) => (p.id > max ? p.id : max),
        values.items[0].id
      );
    }
    let _temp = [
      ...values.items,
      {
        id: maxId + 1,
        material_id: "", //snake casing because the back end expects this way only, otherwise I'd have to transform the array before sending the object which is too much of a hassle.
        material_name: "",
        quantity: "",
        rate: "",
        tax: "",
        amount: "",
      },
    ];
    setFieldValue("items", _temp);
  };

  const removeMaterial = (id) => {
    let newMatArr = values.items.filter((el) => el.id !== id);
    setFieldValue("items", newMatArr);
  };

  const [deletePopup, setDeletePopup] = useState(false);
  const showPopconfirm = () => {
    setDeletePopup(true);
  };

  const recipeErrors = useMemo(() => {
    const err = (errors.items || []).find((el) => el);
    console.log(errors.items);
    console.log(err);
    if (err?.material_id) return <div>You must enter a material</div>;
    else if (err?.quantity) return <div>{err?.quantity}</div>;
    else if (err?.rate) return <div>{err?.rate}</div>;
    return <div></div>;
  }, [errors.items]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Spin spinning={loading}>
        <Row gutter={0}>
          <Col span={4} offset={0}>
            <p>Supplier Name*</p>
            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.supplier_id}
              onChange={(v) => {
                setFieldValue("supplier_id", v);
              }}
              onBlur={handleBlur("supplier_id")}
            >
              {suppliers
                ?.filter((e) => e.status === "active")
                .map((i) => {
                  return (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  );
                })}
            </Select>
            {getErrorMsg(touched.supplier_id, errors.supplier_id)}
          </Col>

          <Col span={6} offset={1}>
            <p>Supplier Address*</p>
            <Select
              placeholder="Select a supplier then an address"
              disabled={!values.supplier_id}
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.supplier_address}
              onChange={(v) => {
                setFieldValue("supplier_address", v);
              }}
            >
              {values.supplier_address_options?.map(
                ({ address1, address2, city, country, id, pincode, state }) => (
                  <Select.Option key={id} value={id}>
                    {getAddress({
                      address1,
                      address2,
                      city,
                      country,
                      pincode,
                      state,
                    })}
                  </Select.Option>
                )
              )}
            </Select>
          </Col>

          <Col span={4} offset={2}>
            <div style={{ display: "flex" }}>
              <p>Created Date*</p>
            </div>
            <DatePicker
              disabled
              showTime={{ use12Hours: true }}
              allowClear={false}
              style={{ width: "100%" }}
              value={moment(values.created_date)}
              format={DATE_TIME_FORMAT}
              onChange={(dt, dtstr) =>
                setFieldValue("created_date", moment(dt).toISOString())
              }
            />
          </Col>
          <Col span={4} offset={2}>
            <div style={{ display: "flex" }}>
              <p>Due Date*</p>
              <p
                style={{
                  color: "crimson",
                  fontSize: "22px",
                  margin: "-5px 0 0 5px",
                }}
              >
                *
              </p>
            </div>
            <DatePicker
              showTime={{ use12Hours: true }}
              allowClear={false}
              style={{ width: "100%" }}
              value={moment(values.due_date)}
              format={DATE_TIME_FORMAT}
              onChange={(dt, dtstr) =>
                setFieldValue("due_date", moment(dt).toISOString())
              }
            />
          </Col>
        </Row>
        <br />

        <Row gutter={0}>
          <Col span={6}>
            <div style={{ display: "flex" }}>
              <p>Payment Terms*</p>
            </div>
            <Select
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.payment_terms}
              onChange={(term) => {
                // setFieldValue("payment_terms", term);
                const termString = paymentTerms?.find(
                  (el) => el.id === term
                )?.name;
                setFieldValue("payment_terms", termString, true);
              }}
            >
              {paymentTerms.map(({ id, name }) => {
                return (
                  <Select.Option key={id} value={id}>
                    {`${name}`}
                  </Select.Option>
                );
              })}
            </Select>
            {getErrorMsg(touched.payment_terms, errors.payment_terms)}
          </Col>
          
          <Col span={6} offset={3}>
          <div style={{ display: "flex" }}>
              <p>Delivery Terms</p>
            </div>

            <Select
            
            showSearch
            filterOption={filterOption}
            style={{width: "100%"}}
            value={values.delivery_terms}
            onChange={(term) => setFieldValue("delivery_terms", null)}>


            </Select>
            {getErrorMsg(touched.delivery_terms, errors.delivery_terms)}

              
          </Col>

          <Col span={6} offset={3}>
            <p>Comments</p>
            <Input.TextArea
              value={values.comments}
              onChange={handleChange("comments")}
              autoSize
            />
          </Col>
        </Row>
        <br />

        <Row gutter={0}>
          <Col span={6} offset={0}>
            <p>Transporter</p>
            <Select
              // showSearch
              showSearch
              filterOption={filterOption}
              style={{ width: "100%" }}
              value={values.transporter}
              onChange={(v) => {
                setFieldValue("transporter", v);
              }}
            >
              {transporters
                ?.filter((e) => e.status === "active")
                .map((i) => {
                  return (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Col>
          <Col span={6} offset={3}>
            <div style={{ display: "flex" }}>
              <p>Transporter Cost</p>
            </div>
            <Input
              value={values.transport_cost}
              type="number"
              onChange={handleChange("transport_cost")}
            />
          </Col>

          <Col span={6} offset={3}>
            <p>Track Code</p>
            <Input
              value={values.track_code}
              onChange={handleChange("track_code")}
            />
          </Col>
        </Row>
        <br />

        <Row gutter={0}>
          <Col span={14} offset={0}>
            <p>Material Items*</p>
          </Col>
          <Col offset={4} span={4}>
            <p>
              Total Amount:{" "}
              {isNaN(values.total_amount) ? 0 : roundValue(values.total_amount)}
            </p>
          </Col>
          <Col offset={0}>
            <Button type="primary" onClick={addMaterial}>
              + Add{" "}
            </Button>
          </Col>
        </Row>

        <Table
          scroll={{
            x: true,
          }}
          columns={createUpdateCols}
          dataSource={values.items}
          pagination={false}
        />

        <Divider className="mt-0.5 mb-0.5" />
        <div>{recipeErrors}</div>
        <div className="flex justify-between">
          <div>
            {po?.fill_status === "NA" && (
              <Popconfirm
                title="Do you want to Delete this order?"
                placement="right"
                okText="Delete"
                cancelText="cancel"
                onConfirm={handleDeleteClick}
                onCancel={() => setDeletePopup(false)}
              >
                <Button
                  htmlType="button"
                  type="danger"
                  onClick={showPopconfirm}
                >
                  Delete Order
                </Button>
              </Popconfirm>
            )}
          </div>
          <div>
            <Button htmlType="button" className="mr-0.5" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              // onClick={handleCuClick}
            >
              {po.id ? "Update Order" : "Create Order"}
            </Button>
          </div>
        </div>
      </Spin>
    </form>
  );
};

const getErrorMsg = (touched, error) => {
  if (touched && error) return error;
  return "";
};

const mapState = (state) => {
  return {
    suppliers: state.data.suppliers,
    materials: state.data.materials,
    transporters: state.data.transporters,
    paymentTerms: state.data.paymentTerms,
  };
};

export default connect(mapState)(CreateOrUpdate);
