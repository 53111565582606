import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Button, Switch, Dropdown, Divider } from "antd";
import { connect } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

// Actions
import { removeUser } from "../actions/authActions";
import { switchTheme } from "../actions/uiActions";

import sunImg from "../assets/images/sun.svg";
import moonImg from "../assets/images/moon.svg";

import logoImg from "../assets/images/logo.png";
import purchaseIcon from "../assets/images/purchaseIcon.png";
import salesIcon from "../assets/images/salesIcon.png";
import makeIcon from "../assets/images/makeIcon.png";
import inventoryIcon from "../assets/images/inventoryIcon.png";
import goodsIcon from "../assets/images/goodsIcon.png";
import partnersIcon from "../assets/images/partnersIcon.png";

const ImgIcon = ({ src, width, isDarkTheme }) => {
  return (
    <img
      alt={"Navigation Icon"}
      style={{
        filter: isDarkTheme ? "brightness(0) invert(1)" : "none",
      }}
      src={src}
      width={width || 30}
    />
  );
};

const Navbar = ({
  removeUser,
  location,
  switchTheme,
  isDarkTheme,
  user,
  ...rest
}) => {
  // If the path contains "invoice", then means we're viewing an invoice, so do not return anything
  if (rest?.history?.location?.pathname?.includes("invoice")) return null;

  const _routing = [
    {
      link: "/",
      name: "Home",
      icon: <img src={logoImg} width={40} alt="AgroTrust" />,
    },
    {
      link: "/inventory",
      name: "Inventory",
      icon: <ImgIcon isDarkTheme={isDarkTheme} src={inventoryIcon} />,
    },
    {
      link: "/goods",
      name: "Goods",
      icon: <ImgIcon isDarkTheme={isDarkTheme} src={goodsIcon} />,
    },
    {
      link: "/purchase",
      name: "Purchase",
      icon: <ImgIcon isDarkTheme={isDarkTheme} src={purchaseIcon} />,
    },

    {
      link: "/make",
      name: "Make",
      icon: <ImgIcon isDarkTheme={isDarkTheme} src={makeIcon} />,
    },
    {
      link: "/sales",
      name: "Sales",
      icon: <ImgIcon isDarkTheme={isDarkTheme} src={salesIcon} />,
    },

    {
      link: "/partners",
      name: "Partners",
      icon: <ImgIcon isDarkTheme={isDarkTheme} src={partnersIcon} />,
    },
  ];

  const dropdownMenu = (
    <Menu>
      <Menu.Item style={{}} key="1">
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      <Divider />
      <Menu.Item key="2" onClick={removeUser}>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Menu mode="horizontal" selectedKeys={location.pathname}>
      {_routing.map(({ link, name, icon }, i) => (
        <Menu.Item key={link} style={{ float: "left" }}>
          <Link to={link}>
            <span className="nav__icon-container">
              {icon} <span style={{ marginLeft: 5 }}>{name}</span>
            </span>
          </Link>
        </Menu.Item>
      ))}

      <Menu.Item>
        <Switch
          onChange={switchTheme}
          checkedChildren={<img src={sunImg} width={20} alt="Light Mode" />}
          unCheckedChildren={<img src={moonImg} width={20} alt="Dark Mode" />}
          checked={!isDarkTheme}
        />
      </Menu.Item>

      <Menu.Item style={{ marginLeft: "auto" }}>
        <Dropdown overlay={dropdownMenu} trigger={["click", "hover"]}>
          <Button type="text">
            <UserOutlined size="large" />
            {user?.name}
          </Button>
        </Dropdown>
      </Menu.Item>
    </Menu>
  );
};

const mapState = (state) => {
  return {
    isDarkTheme: state.ui.isDarkTheme,
    user: state.auth.user,
  };
};

const mapDispatch = {
  removeUser,
  switchTheme,
};

export default connect(mapState, mapDispatch)(withRouter(Navbar));
