import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: "Lato",
    fontSize: 9,
  },
  flex: {
    flexDirection: "row",
  },
  flexCol: {
    flexDirection: "column",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  textCenter: {
    textAlign: "center",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  textRight: {
    textAlign: "right",
  },
  s12: {
    fontSize: 12,
  },
  borderOne: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "grey",
  },
  borderLeft: {
    borderLeft: 1,
    borderStyle: "solid",
    borderColor: "grey",
  },
  borderLeftZero: {
    borderLeft: 0,
  },
  borderTopZero: {
    borderTop: 0,
  },
  borderRightZero: {
    borderRight: 0,
  },
  borderBottomZero: {
    borderBottom: 0,
  },
  paddingFive: {
    padding: 5,
  },
  paddingZero: {
    padding: 0,
  },
  paddingTopZero: {
    paddingTop: 0,
  },
  paddingBottomZero: {
    paddingBottom: 0,
  },
  marginBottomZero: {
    marginBottom: 0
  },  
  flexOne: {
    flex: 1,
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Lato Bold",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  italic: {
    // fontStyle: "italic",
    fontFamily: "Lato Italic",
  },
  flexTable: {
    flexDirection: "column",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "lightgrey",
    marginTop: 10,
  },
  flexTableHeader: {
    backgroundColor: "darkslategrey",
    color: "white",
    // padding: 5,
  },
  flexTableTd: {
    flex: 1,
    padding: 5,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: "lightgrey",
  },
  tableHeaderContent: {
    padding: 5,
    borderColor: "grey",
  },
  fontZeroPointNineEm: {
    fontSize: 10,
  },
  columnSNo: {
    width: 25,
    maxWidth: 25,
    minWidth: 25,
  },
  columnDescription: {
    width: 180,
    minWidth: 180,
    maxWidth: 180,
  },
  columnGst: {
    width: 80,
    minWidth: 80,
    maxWidth: 80,
  },
  columnHsn: {
    width: 40,
    minWidth: 40,
    maxWidth: 40,
  },
  columnRate: {
    width: 60,
    maxWidth: 60,
    minWidth: 60
  },
  columnQty: {
    width: 50,
    minWidth: 50,
    maxWidth: 50
  },  
  columnRegular: {
    width: 60,
    minWidth: 60,
    maxWidth: 60,
  },
  columnDiscount: {
    width: 40,
    maxWidth: 40,
    minWidth: 40
  },
  amountStyle: {
    width: 70,
    maxWidth: 70,
    minWidth: 70,
    alignItems: "flex-end",
  },

  
  columnResourceName: {
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },
  columnTaskType: {
    width: 150,
    minWidth: 150,
    maxWidth: 150,
  },


  tableValue: {
    width: 60,
    fontSize: 10,
  },
});

export const comb = StyleSheet.create({
  sourceDestBox: {
    ...styles.flexCol,
    ...styles.flexOne,
    // ...styles.paddingFive,
    borderWidth: 0.5,
    borderColor: "grey",
    borderRadius: 5,
    overflow: "hidden",
  },
  fromToText: {
    // backgroundColor: "#0a381d",
    backgroundColor: "black",
    textTransform: "uppercase",
    color: "white",
    padding: 5,
    ...styles.bold,
  },
  termsText: {
    backgroundColor: "lightgrey",
    color: "black",
  },
  breakUp: {
    left: {
      ...styles.flexOne,
    },
    right: {
      ...styles.flex,
      ...styles.amountStyle,
      alignItems: "flex-start",
      paddingRight: 5,
      justifyContent: "flex-end",
      ...styles.textRight,
      // borderWidth: 1,
    },
  },
});
