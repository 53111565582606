import React, { useEffect, useState } from "react";
import axios from "axios";
import { DatePicker, Select, Row, Col, Button, Table } from "antd";

import moment from "moment";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import useTableSearch from "../../../common/hooks/useTableSearch";

const ReportForPaymentTerms = () => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [salesByPaymentTerms, setSalesByPaymentTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");

  const getPaymentTermStats = async () => {
    try {
      setIsLoading(true);

      let url = `/api/report/gbPaymentTerm?start_date=${moment(
        startDate
      ).toISOString()}&last_date=${moment(endDate).toISOString()}`;
      console.log(url, "url for payment stats");
      const { data } = await axios.get(url);
      setSalesByPaymentTerms(data);
      console.log(data, "this is data @@@@group by payment terms");
    } catch (error) {
      window.alert(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentTermStats();
  }, [startDate, endDate]);

  const columns = [
    {
      title: "Payment Terms",
      dataIndex: "payment_terms",
      ...useTableSearch("payment_terms"),
    },
    {
      title: "Total No. of Sale",
      dataIndex: "number_of_entries",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <DatePicker.RangePicker
          onCalendarChange={(dates, dateStrings, range) => {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
          }}
          allowClear={false}
          disabledDate={(d) => !d || d.isAfter(moment())}
          value={[startDate, endDate]}
        />

        <div>
          <Button type="primary" style={{ marginLeft: "0.5rem" }}>
            <CSVLink
              data={salesByPaymentTerms}
              filename={"salesByPaymentTerms.csv"}
            >
              Export
            </CSVLink>
          </Button>
        </div>

        {/* <Button style={{marginLeft: "0.5rem"}}>
        <CSVLink data={localSalesOrders} filename={"filename.csv"}>Export</CSVLink>
        </Button> */}
      </div>

      <div style={{ marginTop: "1rem" }}>
        <Table
          scroll={{
            x: true,
          }}
          // loading={isLoading}
          columns={columns}
          dataSource={salesByPaymentTerms}
        />
      </div>
    </div>
  );
};

export default ReportForPaymentTerms;
