import { useState } from "react";
import {Row, Col, Button } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import useTableSearch from "../../../common/hooks/useTableSearch";
import Groups from "./Groups";
import SubGroups from "./Subgroups";

function Groups_Subgroups() {

  return (
    <>
      <Row gutter={16}>
        <Col span={12}> <Groups /></Col>
        <Col span={12}> <SubGroups /></Col>
      </Row>
    </>
  );
}

export default Groups_Subgroups;
