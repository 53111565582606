import React, { useEffect, useState, useRef } from "react";
import { Table, Row, Col, Input, Space, Button, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import useTableSearch from "../../../common/hooks/useTableSearch";
import {
  DownloadOutlined,
} from "@ant-design/icons";
const GRNSummary = () => {
  const [data, setData] = useState([]);
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    listAPICall();
  }, []);

  const listAPICall = async () => {
    try {
      // const { data } = await axios.get(`/api/purchase/grn/list`);
      const {data} = await axios.get(`/api/purchase/grn/list`);
      

      // setMaterials(allMaterials.data);

      // let _data = data.map((el) => {
      //   return {
      //     ...el,
      //     material_name:
      //       allMaterials?.data?.find((_mat) => _mat.id === el.material_id)
      //         ?.name || "Not found",
      //   };
      // });
      // setData(_data);
      setData(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const columns = [
    {
      title: "GRN #",
      dataIndex: ["grn_t", "grn_id"] ,
      // ...useTableSearch(["grn_t", "grn_id"] ),
    },
    { title: "PO #", dataIndex: ["partner", "id"], ...useTableSearch("") },
    {
      title: "Supplier Name" , dataIndex: ["partner", "name"], ...useTableSearch("name")
    },

    { title: "Amount of PO", dataIndex: ["po", "total_amount"] },

    {
      title: "Actions",
      width: 160,
      render: (value, record) => {
        return (
          <>

            <Tooltip title="Download">
              <Button
                danger
                shape="circle"
                icon={<DownloadOutlined />}
                onClick={() => {
                  window.open(`/invoices/grn/${record.grn_t.grn_id}`, "_blank");
                }}
              />
            </Tooltip>
          </>
        );
      },
    }

  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={4} offset={22}></Col>
      </Row>
      <br />
      <Table columns={columns} dataSource={data} />
    </>
  );
};

export default GRNSummary;
