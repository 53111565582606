import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import combinedReducers from "./reducers";
import { logger } from "redux-logger";

const initState = {
  auth: {
    isLoggedIn: localStorage.jwtToken ? true : false,
  },
};

const middlewares = [thunk];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const store = createStore(
  combinedReducers,
  initState,
  // applyMiddleware(thunk),
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export default store;
