import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { PDFViewer, Font } from "@react-pdf/renderer";

import PurchaseOrderInvoice from "./pdf/bills/purchaseOrderInvoice";
import SalesOrderInvoice from "./pdf/bills/salesOrderInvoice";
import SalesOrderInvoice2 from "./pdf/bills/salesOrderInvoice/salesOrderInvoice2";
import MakeOrderInvoice from "./pdf/bills/makeOrderInvoice";
import { connect } from "react-redux";
import GrnPdf from "./pdf/bills/grn";

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

Font.registerHyphenationCallback((word) => [word]);

const INVOICE_MAPPING = {
  purchase: "po",
  sales: "so",
  make: "mo",
  grn: "grn"
};

const Invoice = ({ organizationId }) => {
  const { type, orderId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState(null);

  const [error, setError] = useState(null);

  const [headerData, setHeaderData] = useState({});

  console.log(headerData, "headerData")

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.title =
      type?.charAt(0).toUpperCase() +
      type.slice(1, type.length) +
      " Order Invoice - Invento";
    getInvoiceData();
    getHeadersForGRN();
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);


  const getHeadersForGRN = async() => {
    try {
      const {data} = await axios.get(`https://staging-sso.agrotrust.io/sso/api/organization/${organizationId[0]._id}`);
      setHeaderData(data)
  
    }catch(error) {
      window.alert(error.message)
    }
  }

  const getInvoiceData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      if(INVOICE_MAPPING[type] === "grn"){
        const {data}  = await axios.get(`/api/purchase/grn/list?id=${orderId}`)
        console.log(data, "grn data")
        const _data = {
          ...data,
          grn_items: data.grn_items
            // .slice(0, 7)
            .map((el, index) => ({ ...el, grn_details: index + 1 })),
        };
        console.log(_data, "_data")
        setInvoiceData(_data);
      }else {
        const { data } = await axios.get(
          `/api/invoice/${INVOICE_MAPPING[type]}?id=${orderId}`
        );
        console.log(data, "this is not grn data")
        const _data = {
          ...data,
          items: data.items
            // .slice(0, 7)
            .map((el, index) => ({ ...el, sNo: index + 1 })),
        };
        setInvoiceData(_data);
      }


      // const data = sampleData;
      // console.log(data, "this is data")

      // const _data = {
      //   ...data,
      //   items: data.items
      //     // .slice(0, 7)
      //     .map((el, index) => ({ ...el, sNo: index + 1 })),
      // };
      // console.log(_data, "_data for GRN ")
      // setInvoiceData(_data);
      setIsLoading(false);
    } catch (error) {
      window.alert(error.message);
      setError(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {JSON.stringify(error)} </div>
      ) : (
        <InvoiceWrapper organizationId={organizationId} type={type} data={invoiceData} headerData={headerData} />
      )}
    </>
  );
};

const InvoiceWrapper = ({ organizationId, type, data, headerData }) => {
  
  const docReturner = () => {
    switch (type) {
      case "purchase":
        return <PurchaseOrderInvoice data={data} />;
      case "sales":
        return organizationId[0]._id === "621346f29ad2ff6d4fecb6b2" ? (
          <SalesOrderInvoice2 data={data} />
        ) : (
          <SalesOrderInvoice data={data} />
        );
      case "make":
        return <MakeOrderInvoice data={data} />;
      case "grn" : 
          return <GrnPdf data={data} headerData={headerData}/>
      default:
        break;
    }
  };

  return (
    <PDFViewer
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      {docReturner()}
    </PDFViewer>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.auth?.user.organizations,
  };
};

export default connect(mapStateToProps)(Invoice);
