import { SWITCH_THEME } from "./actionTypes";


/**
 * Dispatcher used to switch the theme
 * @returns {Object} - with key SWITCH_THEME so that the appropriate reducer is triggered
 */
export const switchTheme = () => ({
  type: SWITCH_THEME
})
