import axios from "axios";
import {
  SET_USER,
  REMOVE_USER,
  FETCH_INITIATE,
  FETCH_SUCCESS,
  FETCH_ERROR,
  CLEAR_ERROR,
} from "./actionTypes";
import jwt_decode from "jwt-decode";
import { getList } from "./dataActions";

/**
 * Sends the login information to the sever
 * @param {Object} userData - Will contain the `username` and `password` values
 * @param {Object} history - History object, as supplied by `react-router`
 */
export const loginUserAction = (userData, history) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: CLEAR_ERROR });
      await dispatch({ type: FETCH_INITIATE });
      const res = await axios.post(
        "https://staging-sso.agrotrust.io/sso/api/user/login",
        userData
      );

      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      const _obj = await tokenToRedux(token);
      await dispatch(_obj);
      await dispatch({ type: FETCH_SUCCESS });
      await dispatch(getList("locations"));
    } catch (error) {
      await dispatch({
        type: FETCH_ERROR,
        error: error.response.data,
      });
    }
  };
};

/**
 * Returns the encoded JWT, and returns the decoded JWT object
 * @param {string} token - the JWT
 * @returns {Object} decoded value of the JWT
 */
export const loginViaToken = (token) => {
  return async (dispatch) => {
    const _obj = await tokenToRedux(token);
    await dispatch(_obj);
    await dispatch(getList("locations")); // also fetch the locations pre-emptively
  };
};

/**
 * Checks wheher the token provided has expired or not. If yes, then triggers a REMOVE_USER to log out. Else, calls the @link setAuthToken
 * @param {string} - The JWT
 * @returns {Object} - if the JWT is expired, returns the type with REMOVE_USER, else returns the Object with decoded JWT in payload
 */
export const tokenToRedux = async (token) => {
  const decoded = await jwt_decode(token);
  if (decoded.exp < Date.now() / 1000) {
    await setAuthToken();
    localStorage.removeItem("jwtToken");
    return {
      type: REMOVE_USER,
    };
  }
  await setAuthToken(token);
  return {
    type: SET_USER,
    payload: {
      data: decoded,
    },
  };
};

/**
 * Sets the token
 * @async
 * @param {String} token - The JWT Token
 */
const setAuthToken = async (token) => {
  if (token) {
    await new Promise((resolve, reject) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      setTimeout(
        () => {
          resolve("done");
        },
        0
        // 3000
      );
    });
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

/**
 * Logs out a user
 * @returns {Object} - with the type "REMOVE_USER"
 */
export const removeUser = () => {
  setAuthToken();
  localStorage.removeItem("jwtToken");
  return {
    type: REMOVE_USER,
  };
};
