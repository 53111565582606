import { Select } from "antd"
import { connect } from "react-redux"
import { setLocation } from "../../actions/dataActions"

const LocationSelect = ({
    locations,
    selectedLocationId,
    setLocation
}) => {
    return <Select
        disabled={!locations.length}
        defaultValue={selectedLocationId}
        onChange={(id) => {
            setLocation(id)
        }}
    >
        {locations.map((loc) => (
            <Select.Option key={loc.id} value={loc.id}>
                {loc.name}
            </Select.Option>
        ))}
    </Select>
}

const mapState = state => {
    return ({
        locations: state.data?.locations || [],
        selectedLocationId: state.data?.selectedLocation?.id
    })
}

const mapDispatch = {
    setLocation
}

export default connect(mapState, mapDispatch)(LocationSelect)