export const DELIVERY_TERMS = ["Door Delivery", "Hub Delivery", "Ex-factory"];

export const PAYMENT_TERMS = [{}]

// export const PAYMENT_TERMS = [
//   {
//     "name": "PIA-Payment in advance (PIA)",
//     "type": "general"
    
//   },1
//   {
    
//     "name": "Net 7-Payment seven days after invoice date (Net 7)",
//     "type": "general"
//   },
//   {
//     "name": "Net 10-Payment ten days after invoice date (Net 10)",
//     "type": "general"
//   },
//   {
    
//     "name": "Net 30-Payment 30 days after invoice date (Net 30)",
//     "type": "general"
//   },
//   {
    
//     "name": "Net 60-Payment 60 days after invoice date (Net 60)",
//     "type": "general"
//   },
//   {
    
//     "name": "Net 90-Payment 90 days after invoice date (Net 90)",
//     "type": "general"
//   },
//   {
    
//     "name": "EOM-End of month (EOM)",
//     "type": "general"
//   },
//   {
    
//     "name": "21 MFI-21st of the month following invoice date (21 MFI)",
//     "type": "general"
//   },
//   {
    
//     "name": "1% 10 Net 30-1% discount if payment received within ten days otherwise payment 30 days after invoice date (1% 10 Net 30)",
//     "type": "general"
//   },///
//   {
    
//     "name": "COD-Cash on delivery (COD)",
//     "type": "general"
//   },
//   {
    
//     "name": "Cash account-Account conducted on a cash basis, no credit (Cash account)",
//     "type": "general"
//   },//
//   {
    
//     "name": "Letter of credit-A documentary credit confirmed by a bank, often used for export (Letter of credit)",
//     "type": "general"
//   },//
//   {
    
//     "name": "Bill of exchange-A promise to pay at a later date, usually supported by a bank (Bill of exchange)",
//     "type": "general"
//   },//
//   {
    
//     "name": "CND-Cash next delivery (CND)",
//     "type": "general"
//   },
//   {
    
//     "name": "CBS-Cash before shipment (CBS)",
//     "type": "general"
//   },
//   {
    
//     "name": "CIA-Cash in advance (CIA)",
//     "type": "general"
//   },
//   {
    
//     "name": "CWO-Cash with order (CWO)",
//     "type": "general"
//   },
//   {
    
//     "name": "1MD-Monthly credit payment of a full month's supply (1MD)",
//     "type": "general"
//   },
//   {
    
//     "name": "2MD-Monthly credit payment of a full month's supply plus an extra calendar month (2MD)",
//     "type": "general"
//   },//
//   {
    
//     "name": "Contra-Payment from the customer offset against the value of supplies purchased from the customer (Contra)",
//     "type": "general"
//   },//
//   {
    
//     "name": "Stage payment-Set payments over a period of time, agreed upon by the client and seller (Stage payment)",
//     "type": "general"
//   },//
//   {
    
//     "name": "Rebate-Refund sent to the buyer after they’ve made a purchase (Rebate)",
//     "type": "general"
//   },//
// ];

export const RECEIPT_STATUS = [
  { text: "Not Received", value: "Not Received" },
  { text: "Partially Received", value: "Partially Received" },
  { text: "Received", value: "Received" },
];

export const ORDER_STATUS = [
  { text: "Pending", value: "Pending" },
  { text: "Approved", value: "Approved" },
  { text: "Closed", value: "Closed" },
];

export const DELIVERY_STATUSES = [
  { text: "Not Dispatched", value: "Not Dispatched" },
  { text: "Dispatched", value: "Dispatched" },
  { text: "Delivered", value: "Delivered" },
];

export const FILL_STATUS = [
  { text: "Not filled", value: "NA" },
  { text: "Partially filled", value: "Partially Filled" },
  { text: "Completely Filled", value: "Completed" },
];

// export const _SUBGROUPS = [
//   { text: "Rice", value: "Rice" },
//   { text: "Heirloom Wheat", value: "Heirloom Wheat" },
//   { text: "Flours", value: "Flours" },
//   { text: "Milllets", value: "Milllets" },
//   { text: "Seeds", value: "Seeds" },
//   { text: "Flakes", value: "Flakes" },
//   { text: "Honey", value: "Honey" },
//   { text: "Jaggery", value: "Jaggery" },
//   { text: "Molasses", value: "Molasses" },
//   { text: "Oils", value: "Oils" },
//   { text: "Ghee", value: "Ghee" },
//   { text: "Rock Salt", value: "Rock Salt" },
//   { text: "Whole Spices", value: "Whole Spices" },
//   { text: "Ground Spices", value: "Ground Spices" },
//   { text: "Dried Herbs", value: "Dried Herbs" },
//   { text: "Ground Herbs", value: "Ground Herbs" },
//   { text: "Dals", value: "Dals" },
//   { text: "Beans", value: "Beans" },
//   { text: "Brown Paper Pouch", value: "Brown Paper Pouch" },
//   { text: "Standup Pouch", value: "Standup Pouch" },
//   { text: "Vaccum Pouch", value: "Vaccum Pouch" },
//   { text: "Gunny Bag", value: "Gunny Bag" },
//   { text: "Bottle", value: "Bottle" },
//   { text: "Plastic Pouch", value: "Plastic Pouch" },
// ];

// export const _MAINGROUPS = [
//   { text: "Grains & Cereals", value: "Grains & Cereals" },
//   { text: "Nuts & Seeds", value: "Nuts & Seeds" },
//   { text: "Sweetners", value: "Sweetners" },
//   { text: "Oils & Ghee", value: "Oils & Ghee" },
//   { text: "Salt", value: "Salt" },
//   { text: "Spices & Herbs", value: "Spices & Herbs" },
//   { text: "Dehydrated", value: "Dehydrated" },
//   { text: "Pulses", value: "Pulses" },
//   { text: "Packaging", value: "Packaging" },
// ];

export const PACK_TYPES = [
  { text: "Brown Paper Pouch", value: "Brown Paper Pouch" },
  { text: "Standup Pouch - 400g", value: "Standup Pouch - 400g" },
  { text: "Standup Pouch - 500g", value: "Standup Pouch - 500g" },
  { text: "Standup Pouch - 700g", value: "Standup Pouch - 700g" },
  { text: "Standup Pouch - 1kg", value: "Standup Pouch - 1kg" },
  { text: "Standup Pouch - 2kg", value: "Standup Pouch - 2kg" },
  { text: "Vaccum Pouch - 500g", value: "Vaccum Pouch - 500g" },
  { text: "Vaccum Pouch - 700g", value: "Vaccum Pouch - 700g" },
  { text: "Vaccum Pouch - 1kg", value: "Vaccum Pouch - 1kg" },
  { text: "Vaccum Pouch - 2kg", value: "Vaccum Pouch - 2kg" },
  { text: "Vaccum Pouch - 5kg", value: "Vaccum Pouch - 5kg" },
  { text: "White Gunny Bag - 50kg", value: "White Gunny Bag - 50kg" },
  { text: "Bottle - 200 ml", value: "Bottle - 200 ml" },
  { text: "Bottle - 500 ml", value: "Bottle - 500 ml" },
  { text: "Bottle - 1 ltr", value: "Bottle - 1 ltr" },
  { text: "Regular Pouch - 1kg", value: "Regular Pouch - 1kg" },
  { text: "Regular Pouch - 2kg", value: "Regular Pouch - 2kg" },
  { text: "Regular Pouch - 5kg", value: "Regular Pouch - 5kg" },
  { text: "Regular Pouch - 10kg", value: "Regular Pouch - 10kg" },
];

export const UOMS = [
  { text: "kg", value: "kg" },
  { text: "g", value: "g" },
  { text: "ltr", value: "ltr" },
  { text: "ml", value: "ml" },
  { text: "pc", value: "pc" },
  { text: "block", value: "block" },
  { text: "sachet", value: "sachet" },
];

export const DATE_TIME_FORMAT = "DD-MMM-YY HH:MM A";
