import React from "react";
import { Text, Font, Page, View, Image, Document } from "@react-pdf/renderer";
import moment from "moment";

import { styles, comb } from "../../styles";
import fallbackLogo from "../../../../assets/images/logo.png";

import taruLogo from "../../../../assets/images/taruLogo.png";
import numberToWords from "../../../../common/functions/numberToWords";
import commaSeparatedRupees from "../../../../common/functions/commaSeparatedRupees";
import getAddress from "../../../../common/functions/getAddress";

const PurchaseOrderInvoice = ({ data }) => {
  const tablesReturner = () => {
    const len = data.items.length;
    const ITEMS_PER_PAGE = 6;
    const MAX_ITEMS_IN_OTHER_PAGES = 15;

    if (len <= ITEMS_PER_PAGE) return <Table items={data.items} />;
    else {
      let _insertedInPage = 0;

      let _firstSix = (
        <>
          <Table items={data.items.slice(0, 6)} />
          <View break />
        </>
      );
      let _combinedArr = [_firstSix];
      for (let i = 6; i < len; i = i + MAX_ITEMS_IN_OTHER_PAGES) {
        let _items = data.items.slice(i, i + MAX_ITEMS_IN_OTHER_PAGES);
        _insertedInPage += _items.length;
        _combinedArr.push(
          <>
            <Table items={_items} />
            {_insertedInPage >= MAX_ITEMS_IN_OTHER_PAGES && <View break />}
          </>
        );
        if (_insertedInPage >= MAX_ITEMS_IN_OTHER_PAGES) _insertedInPage = 0;
      }
      return _combinedArr;
    }
  };
  return (
    <Document title={"Purchase Order Id " + data?.po_details?.id}>
      <Page size="A4" style={styles.page}>
        <Text
          style={[
            styles.upperCase,
            styles.bold,
            {
              marginBottom: 10,
              fontSize: 20,
              textAlign: "center",
              color: "darkgreen",
            },
          ]}
        >
          Purchase Order
        </Text>

        <InvoiceHeader poDetails={data.po_details} headerInfo={data.header} />

        <SourceDestination
          supplier={data.supplier}
          mainLoc={data.header}
          locDetails={data.location}
        />

        <TermsOfInvoice poDetails={data.po_details} />

        {tablesReturner()}

        <CommentsAndBreakUp items={data.items} poDetails={data.po_details} />

        <ClosingNotes />
      </Page>
    </Document>
  );
};

const InvoiceHeader = ({ poDetails, headerInfo }) => {
  const { id, created_date } = poDetails;

  const { GST, address, city, email, logo, name, pincode, state } = headerInfo;

  return (
    <>
      <View style={[styles.flex, styles.justifyBetween, { marginBottom: 10 }]}>
        {/* Top Left */}
        <View
          style={[
            styles.flexCol,
            styles.fontZeroPointNineEm,
            { maxWidth: 300 },
          ]}
        >
          <Text style={[styles.bold, styles.upperCase, { fontSize: 12 }]}>
            {name}
          </Text>
          <Text>{address}</Text>
          <Text>
            {state}, {pincode}
          </Text>
          {/* GSTIN */}
          <Text style={{ paddingTop: 5 }}>
            <Text style={styles.bold}>GSTIN / UIN: </Text>
            <Text>{GST}</Text>
          </Text>
          {/* State Name */}
          <Text>
            <Text style={styles.bold}>State Name: </Text>
            <Text>{state}</Text>
          </Text>
          {/* Email */}
          <Text>
            <Text style={styles.bold}>Email: </Text>
            <Text style={{ color: "darkblue" }}>{email}</Text>
          </Text>
        </View>

        {/* Top Right */}

        <View style={styles.flexCol}>
          <Image
            src={headerInfo?.logo || fallbackLogo}
            style={{
              width: 60,
              height: 40,
              margin: "auto",
              marginBottom: 10,
            }}
          />
          <Text>
            <Text style={styles.bold}>Date:</Text>
            <Text>{moment(created_date).format("Do MMMM YYYY")}</Text>
          </Text>
          <Text>
            <Text style={styles.bold}>PO #: </Text>
            <Text> {id}</Text>
          </Text>
        </View>
      </View>
      <View
        style={{
          height: 1,
          backgroundColor: "grey",
          width: "100%",
          marginBottom: 20,
        }}
      />
    </>
  );
};

const SourceDestination = ({ supplier, mainLoc, locDetails }) => {
  const {
    name,
    contact,
    gstin,
    email,
    address1,
    address2,
    city,
    state,
    pincode,
  } = supplier || "";

  return (
    <View style={[styles.flex, { marginBottom: 10 }]}>
      <View style={[comb.sourceDestBox, { marginRight: 10 }]}>
        <Text style={comb.fromToText}>Supplier (Bought from)</Text>
        <View style={[styles.paddingFive, styles.flexCol]}>
          <View>
            <Text style={[styles.bold, styles.upperCase]}>{name}</Text>
          </View>
          {/* first address */}
          <View>
            <Text>
              {getAddress({ address1, address2, city, pincode })}
              {/* {address1}, {address2}, {city}, {pincode} */}
            </Text>
          </View>
          {/* GSTIN/UIN */}
          <View>
            <Text>
              <Text style={styles.bold}>GSTIN/UIN </Text>: {gstin}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>State </Text>: {state}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>email</Text>: {email}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>Phone</Text>: {contact}
            </Text>
          </View>
        </View>
      </View>

      <View style={[comb.sourceDestBox]}>
        <Text style={comb.fromToText}>Buyer (Bill To)</Text>
        <View style={[styles.paddingFive, styles.flexCol]}>
          <View>
            <Text style={[styles.bold, styles.upperCase]}>{mainLoc.name}</Text>
          </View>
          {/* first address */}
          <View>
            <Text>
              {getAddress(locDetails)}
              {/* 110, Krishna Bhavan, Annex 22/B, B.S.D. Road, Deonar, Mumbai -
              400088. */}
            </Text>
          </View>
          {/* GSTIN/UIN */}
          <View>
            <Text>
              <Text style={styles.bold}>GSTIN/UIN </Text>: {locDetails.gstin}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>State Name</Text>: {locDetails.state}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>email</Text>: {mainLoc.email}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>Phone</Text>:{" "}
              {locDetails.contact || "(Not provided)"}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const TermsOfInvoice = ({ due_date, ...rest }) => {
  const { payment_terms } = rest?.poDetails || "";

  return (
    <View style={[styles.flex, { marginBottom: 10 }]}>
      <FromToText heading="Payment Terms" mainBody={`${payment_terms}`} />
      <FromToText heading="Delivery Terms" mainBody="Delivery terms here" />
      <FromToText heading="Shipping Method" mainBody="Shipping method here" />
      <FromToText
        heading="Required by Date"
        mainBody={moment(due_date).format("Do MMMM YYYY")}
      />
    </View>
  );
};

const FromToText = ({ heading, mainBody, children }) => {
  return (
    <View style={[comb.sourceDestBox, { marginRight: 3 }]}>
      <Text style={[comb.fromToText, comb.termsText]}>{heading}</Text>

      <View style={styles.paddingFive}>
        <Text>{mainBody}</Text>
        {children}
      </View>
    </View>
  );
};

const Table = ({ items }) => {
  return (
    <View style={[styles.flexTable]}>
      <View style={[styles.flexTableHeader, styles.flex]}>
        <View style={[styles.flexTableTd, styles.columnOne]}>
          <Text>S.No</Text>
        </View>

        <View
          style={[
            styles.flexTableTd,
            styles.columnDescription,
            {
              width: 200,
              minWidth: 200,
              maxWidth: 200,
            },
          ]}
        >
          <Text>Description</Text>
        </View>

        <View style={[styles.flexTableTd, styles.columnHsn]}>
          <Text>HSN / SAC</Text>
        </View>

        <View style={[styles.flexTableTd, styles.columnRegular]}>
          <Text style={styles.textRight}>Quantity</Text>
        </View>

        <View style={[styles.flexTableTd, { width: 50, maxWidth: 50 }]}>
          <Text style={{ textAlign: "center" }}>Unit</Text>
        </View>

        <View style={[styles.flexTableTd, { width: 60, maxWidth: 60 }]}>
          <Text style={styles.textRight}>Rate</Text>
        </View>

        <View style={[styles.flexTableTd, { width: 40, maxWidth: 40 }]}>
          <Text style={styles.textRight}>Disc %</Text>
        </View>

        <View
          style={[
            styles.flexTableTd,
            styles.amountStyle,
            styles.borderRightZero,
            styles.textRight,
          ]}
        >
          <Text style={styles.textRight}>Amount (Rs)</Text>
        </View>
      </View>

      <TableData items={items} />
    </View>
  );
};

const TableData = ({ items }) => {
  return (
    <>
      {items?.map(
        ({ name, id, quantity, uom, rate, amount, maingroup, sNo }) => {
          return (
            <View
              key={id}
              style={{
                flexDirection: "row",
                borderBottomWidth: 1,
                borderBottomColor: "lightgrey",
                backgroundColor: sNo % 2 ? "white" : "whitesmoke",
              }}
            >
              <View style={[styles.flexTableTd, styles.columnOne]}>
                <Text>{sNo}</Text>
              </View>
              <View
                style={[
                  styles.flexTableTd,
                  styles.flexCol,
                  styles.columnDescription,
                  {
                    width: 200,
                    minWidth: 200,
                    maxWidth: 200,
                  },
                ]}
              >
                <Text style={styles.bold}>{name}</Text>
                <Text style={styles.italic}>{maingroup}</Text>
              </View>
              <View style={[styles.flexTableTd, styles.columnHsn]}>
                <Text style={styles.textRight}>1701</Text>
              </View>
              <View style={[styles.flexTableTd, styles.columnRegular]}>
                <Text style={styles.textRight}>{quantity}</Text>
              </View>

              <View style={[styles.flexTableTd, { width: 50, maxWidth: 50 }]}>
                <Text style={{ textAlign: "center" }}>{uom}</Text>
              </View>

              <View style={[styles.flexTableTd, { width: 60, maxWidth: 60 }]}>
                <Text style={styles.textRight}>{rate}</Text>
              </View>

              <View style={[styles.flexTableTd, { width: 40, maxWidth: 40 }]}>
                <Text style={styles.textRight}>NA</Text>
              </View>

              <View
                style={[
                  styles.flexTableTd,
                  styles.borderRightZero,
                  styles.amountStyle,
                  styles.textRight,
                ]}
              >
                <Text style={styles.textRight}>
                  {commaSeparatedRupees(amount)}
                </Text>
              </View>
            </View>
          );
        }
      )}
    </>
  );
};

const CommentsAndBreakUp = ({
  poDetails: { total_amount, transport_cost },
  items = [],
}) => {
  let totalTaxableAmt = 0;
  let totalGST = 0;
  let netTotalAmt = 0;

  items.forEach(({ tax, quantity, rate, amount, uom, discount, ...rest }) => {
    let temp = quantity * rate;
    let discountedTotal = temp - (temp * (discount || 0)) / 100;
    totalTaxableAmt += discountedTotal;
    totalGST += (temp * tax) / 100;
  });

  netTotalAmt = totalTaxableAmt + totalGST + transport_cost + 0;

  return (
    <>
      <View style={[styles.flex, { justifyContent: "space-between" }]}>
        <View style={{ marginTop: 5, width: "60%" }}>
          <FromToText heading="Comments / Special Instructions">
            <View style={styles.flexCol}>
              <Text>- Please send two copies of your invoice.</Text>
              <Text>
                - Enter this order in accordance with the prices, terms,
                delivery method, and specifications listed above.
              </Text>
              <Text>
                - Please notify us immediately if you are unable to ship as
                specified.
              </Text>

              {/* {comments && ( */}
              <>
                <View
                  style={{
                    height: 1,
                    backgroundColor: "lightgrey",
                    marginBottom: 2,
                  }}
                />
              </>
              {/* )} */}
            </View>
          </FromToText>
        </View>

        <View
          style={[styles.flexOne, styles.flexCol, { alignItems: "flex-end" }]}
        >
          <View
            style={{
              alignItems: "flex-end",
              width: "70%",
              // width: "25%",
            }}
          >
            <View style={[styles.flexCol, { width: "100%" }]}>
              {/* Subtax, tax, etc. */}
              <View style={[styles.flex]}>
                <Text style={comb.breakUp.left}>Total Taxable Amt</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(totalTaxableAmt)}
                  </Text>
                </View>
              </View>

              <View style={styles.flex}>
                <Text style={comb.breakUp.left}>GST</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(totalGST)}
                  </Text>
                </View>
              </View>

              <View style={styles.flex}>
                <Text style={comb.breakUp.left}>Shipping</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(transport_cost)}
                  </Text>
                </View>
              </View>

              <View style={styles.flex}>
                <Text style={comb.breakUp.left}>Other</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {" "}
                    {commaSeparatedRupees(0)}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.flex,
                  styles.bold,
                  { marginTop: 1, borderTopWidth: 1 },
                ]}
              >
                <Text style={comb.breakUp.left}>Total</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(netTotalAmt)}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.flex,
                  styles.bold,
                  { marginTop: 1, borderTopWidth: 1 },
                ]}
              >
                <Text style={comb.breakUp.left}>Total (rounded)</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(Math.round(netTotalAmt))}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 3, minWidth: "50%" }}>
            <Text
              style={{ backgroundColor: "black", color: "white", padding: 1 }}
            >
              IN WORDS:
            </Text>
            <Text style={styles.bold}>
              {numberToWords(Math.round(netTotalAmt))}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const ClosingNotes = () => {
  return (
    <>
      <View
        style={[
          styles.flex,
          styles.justifyCenter,
          {
            marginTop: 30,
            borderTopWidth: 1,
            borderTopColor: "lightgrey",
            paddingTop: 3,
          },
        ]}
      >
        <Text>
          If you have any questions about this purchase order, please contact
          [Name, Phone #, E-mail]
        </Text>
      </View>
    </>
  );
};

export default PurchaseOrderInvoice;
