import {Popconfirm, message} from "antd"

function DeleteConfirm({ id, handleDelete, title, children}) {
    function cancel() {
        // message.error(`${title} not deleted`);
    }

    function confirm() {
        handleDelete(id);
        message.success(`${title} deleted successfully`);
    }

  return (
    <Popconfirm
    title={`Are you sure you want to delete this ${title}?`}
    onConfirm={confirm}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    {children}
  </Popconfirm>
  )
}

export default DeleteConfirm;
