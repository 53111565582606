import React, { useEffect, useState } from "react";
import axios from "axios";
import { DatePicker, Button, Table, Select, Input, InputNumber } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import useTableSearch from "../../../common/hooks/useTableSearch";
import commaSeparatedRupees from "../../../common/functions/commaSeparatedRupees";

const { Option } = Select;

const ReportsOfNGOList = () => {
  const FilterBy = [
    { key: 1, label: "Top NGOs" },
    { key: 2, label: "Bottom NGOs" },
  ];

  const groupFilter =  [
    {key:1, label: "By Revenue"}, 
    {key:2, label: "By Quantity Sold"}
  ]
    
  
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [NGOsReport, setNGOsReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState("Top NGOs");
  const [groupByFilter, setGroupByFilter] = useState("By Revenue");
  const [limit, setLimit] = useState(5);

  const getTopNGOsByRevenue = async () => {
    let url = `/api/report/ngoListBoR?start_date=${moment(
      startDate
    ).toISOString()}&last_date=${moment(
      endDate
    ).toISOString()}&limit=${limit}&order=DESC`;
    const { data } = await axios.get(url);
    setNGOsReport(data);
  };

  const getTopNGOsByQuantity = async() => {
    let url =`/api/report/ngoListBoUS?start_date=${moment(startDate).toISOString()}&last_date=${moment(endDate).toISOString()}&limit=${limit}&order=DESC`;
    const {data} = await axios.get(url);
    setNGOsReport(data)
  }

  const getBottomNGOsByRevenue = async () => {
    let url = `/api/report/ngoListBoR?start_date=${moment(
        startDate
      ).toISOString()}&last_date=${moment(
        endDate
      ).toISOString()}&limit=${limit}&order=ASC`;
    const { data } = await axios.get(url);
    setNGOsReport(data);
  };

 const getBottomNGOsByQuantity = async() => {
    let url = `/api/report/ngoListBoUS?start_date=${moment(startDate).toISOString()}&last_date=${moment(endDate).toISOString()}&limit=${limit}&order=ASC`;
    const {data} = await axios.get(url);
    setNGOsReport(data);
  }

  useEffect(() => {
    if(filterOptions === "Top NGOs"){
        if(groupByFilter === "By Revenue") {
            getTopNGOsByRevenue();
        }else {
            getTopNGOsByQuantity();
        }
       
    }else {
        if(groupByFilter === "By Revenue"){
            getBottomNGOsByRevenue();
        }else {
            getBottomNGOsByQuantity();
        }
        
    }
  }, [startDate, endDate, limit, filterOptions, groupByFilter])


  const columns = [
    {
      title: "NGO Name",
      dataIndex: "name",
      ...useTableSearch("name"),
    },
    {
      title: `${
        groupByFilter === "By Revenue" ? "Revenue" : "Quantity"
      }`,
      dataIndex: `${
        groupByFilter === "By Revenue"
          ? "revenue_generated"
          : "total_units_sold"
      }`,
      render: (value, record, index) => commaSeparatedRupees(value)
    },
  ];
  return (
    <div>
      <div>Filters</div>
      <div className="flex justify-between">
        <DatePicker.RangePicker
          onCalendarChange={(dates, dateStrings, range) => {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
          }}
          allowClear={false}
          disabledDate={(d) => !d || d.isAfter(moment())}
          value={[startDate, endDate]}
        />

        <div>
          <Select
            style={{ width: "12rem" }}
            placeholder="Filter by"
            defaultValue={"Top NGOs"}
            value={filterOptions}
            onChange={(v) => setFilterOptions(v)}
          >
            {FilterBy.map(({ key, label }) => {
              return (
                <Option key={key} value={label}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </div>

        <div>
          <Select
            style={{ width: "12rem" }}
            placeholder="Filter by"
            defaultValue={"By Revenue"}
            value={groupByFilter}
            onChange={(v) => setGroupByFilter(v)}
          >
            {groupFilter.map(({ key, label }) => {
              return (
                <Option key={key} value={label}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </div>

        <div>
          <span>Limit : </span>
          <InputNumber
            min={1}
            defaultValue={5}
            value={limit}
            onChange={(v) => setLimit(v)}
          />
        </div>

        <div>
          <Button type="primary" style={{ marginLeft: "0.5rem" }}>
            <CSVLink data={NGOsReport} filename={"NGOList.csv"}>
              Export
            </CSVLink>
          </Button>
        </div>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <Table
          scroll={{
            x: true,
          }}
          // loading={isLoading}
          columns={columns}
          dataSource={NGOsReport}
        />
      </div>
    </div>
  );
};


export default ReportsOfNGOList;