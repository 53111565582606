import { useState, useEffect } from "react";
import { message } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import SkusCreateUpdateForm from "./goods.skus.form";
import axios from "axios";
import { UOMS } from "../../../assets/constants";

const CreateUpdateSku = ({ selectedSku, materials, onCancel, onOk }) => {
  const [hsnList, setHsnList] = useState([]);

  const getHsn = async () => {
    try {
      let { data } = await axios.get("/api/tax/list");
      data = data.filter((el) => el?.type?.toLowerCase() === "hsn");
      setHsnList(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  useEffect(() => {
    getHsn();
  }, []);

  const getInitalValues = (selectedSku) => {
    if (selectedSku?.id) {
      const {
        status,
        product_name,
        display,
        quantity,
        uom,
        weight,
        shelfLife,
        hsnId,
        tax,
        barcode,
        recipe,
      } = selectedSku;

      return {
        status,
        product_name,
        display,
        quantity,
        uom,
        weight,
        shelfLife,
        hsnId,
        tax,
        barcode,
        recipe,
      };
    }

    return {
      status: "active",
      product_name: "",
      display: "",
      quantity: "",
      uom: UOMS[0].value, //kg
      weight: "",
      shelfLife: null,
      tax: "",
      barcode: "",
      recipe: [
        {
          material_id: null,
          weight: "",
          material_name: "",
          uom: "",
        },
      ],
    };
  };

  const getValidationSchema = () => {
    return {
      product_name: yup.string().required("Please enter a Product Name"),
      display: yup.string().required("Please enter a Display Name"),
      quantity: yup.number().required("Please enter Quantity"),
      uom: yup.string().required("Please enter a UOM"),
      weight: yup.string().required("Please enter Weight"),
      shelfLife: yup.number().nullable(),
      tax: yup.number().required("Pease enter a tax"),
      recipe: yup
        .array()
        .of(
          yup.object().shape({
            material_id: yup.number().required("Material cannot be empty"),
            weight: yup.number().required("Quantity used cannot be empty"),
          })
        )
        .min(1)
        .required("Please add atleast one recipe"),
      status: yup
        .mixed()
        .oneOf(
          ["active", "inactive"],
          "Status must be either active or inactive"
        ),
    };
  };

  const prepareForOk = (finalValues, isEditMode = false) => {
    let _finalObject = {
      ...finalValues,
      collection: null,
    };

    let _r = finalValues.recipe.map(
      ({ material_id, weight, material_name, uom }) => ({
        material_id,
        weight,
        material_name,
      })
    );

    _finalObject["recipe"] = _r;
    _finalObject["expiry"] = finalValues.shelfLife;
    _finalObject["quantity"] = parseInt(finalValues.quantity);
    delete _finalObject["shelfLife"];
    _finalObject[
      "sku"
    ] = `${finalValues.product_name} / ${finalValues.quantity} ${finalValues.uom}`;

    if (isEditMode) _finalObject.id = selectedSku.id;
    onOk(_finalObject);
  };

  const createOrUpdate = (values) => {
    let finalValues = values;
    let isEditMode = !!selectedSku.id;
    prepareForOk(finalValues, isEditMode);
  };

  return (
    <>
      <Formik
        initialValues={getInitalValues(selectedSku)}
        validationSchema={yup.object().shape(getValidationSchema())}
        onSubmit={createOrUpdate}
      >
        {(formikProps) => (
          <SkusCreateUpdateForm
            {...formikProps}
            hsnList={hsnList}
            onCancel={onCancel}
            materials={materials}
            selectedSku={selectedSku}
          />
        )}
      </Formik>
    </>
  );
};

export default CreateUpdateSku;
