/** @module UtilityFunctions **/

/**
 * @function minutesToDuration
 * @description Converts minutes into hours and minutes
 * @param {Number} noOfMinutes - The number of minutes
 * @return {string} in HH:MM format
 * @memberof UtilityFunctions
 * @example minutesToDuration(600);
 * //Output: "05:00"
 */
export default function minutesToDuration(noOfMinutes) {
    if (noOfMinutes < 60) return `00:${noOfMinutes.toString().padStart(2, '0')} hrs`
    const padZero = num => num?.toString()?.padStart(2, '0')
    return (`${padZero(noOfMinutes / 60)}:${padZero(noOfMinutes % 60)} hrs`)
}