// /** @module UtilityFunctions **/

// /**
//  * @function commaSeparatedRupees
//  * @description Will convert a provided number into a string having commas in places following the Indian Currency format
//  * @param {Number} number - The number to format
//  * @param {boolean} showSymbol - (Optional), whether or not to show the Rupee Symbol (₹) along with the output
//  * @return {string} number converted into words
//  * @memberof UtilityFunctions
//  * @example commaSeparatedRupees(100000, false); //second argument is optional
//  * //Output: "1,00,000.00"
//  * commaSeparatedRupees(100000, true);
//  * //Output: "₹10,00,000.00"
//  */


/**
 * return full name of the user
 * @param   {string} firstName  First Name of the User
 * @param   {string} lastName   Last Name of the User
 * @return  {string}            Fullname of the user
 */
export default function commaSeparatedRupees(number = 0, showSymbol = false) {
  let _secondArg = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
  if (showSymbol) {
    _secondArg["style"] = "currency";
    _secondArg["currency"] = "INR";
  }
  return number ? number.toLocaleString("en-IN", _secondArg) : "0";
}