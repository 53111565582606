import { Button, Input, Form, Modal } from "antd";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";

const getInitalValues = (selectedLoc) => {
  if (selectedLoc) {
    const {
      name,
      address1,
      address2,
      city,
      state,
      pincode,
      status,
      gstin,
      is_virtual,
      checkpoint,
    } = selectedLoc;

    return {
      name,
      checkpoint,
      gstin,
      is_virtual,
      address1,
      address2,
      city,
      state,
      pincode,
      status,
    };
  }

  return {
    name: "",
    checkpoint: "",
    gstin: "",
    is_virtual: 0,
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
    status: "active",
  };
};

const getValidationSchema = () => {
  return {
    name: yup.string().required("Please enter a location"),
    checkpoint: yup.string(),
    gstin: yup.string().required("Please enter a GSTIN"),
    is_virtual: yup.mixed(),
    address1: yup.string().required("Please enter a location"),
    address2: yup.string(),
    city: yup.string(),
    state: yup.string().required("Please select a state"),
    pincode: yup.number().required("Please enter a Pin Code"),
    status: yup
      .mixed()
      .oneOf(
        ["active", "inactive"],
        "Status must be either active or inactive"
      ),
  };
};

const CreateUpdateLocation = ({
  selectedLoc,
  showCreateUpdateModal,
  createLocHandler,
  cancelHandler,
}) => {
  // const [form] = Form.useForm();

  const [pincodeLoading, setPincodeLoading] = useState(false);

  const getDataFromPincode = async (pincode, setFieldValue) => {
    try {
      if (pincode.toString().trim().length !== 6) {
        window.alert("Please enter complete pin code");
      }
      setPincodeLoading(true);

      const response = await fetch(
        "https://api.postalpincode.in/pincode/" + pincode
      );
      const pincodes = await response.json();

      const { Name, Block, District, Division, State } =
        pincodes[0]?.PostOffice?.[0] || "";

      setFieldValue("state", State);
      setFieldValue("address1", Name);
      setFieldValue("address2", District);
      setFieldValue("city", Division);
    } catch (error) {
      window.alert(error.message);
    } finally {
      setPincodeLoading(false);
    }
  };

  const removeSameValues = (mainObject, referenceObj) => {
    for (const [key, value] of Object.entries(mainObject)) {
      if (mainObject[key] === referenceObj[key]) delete mainObject[key];
    }
  };

  return (
    <Formik
      initialValues={getInitalValues(selectedLoc)}
      validationSchema={yup.object().shape(getValidationSchema())}
      onSubmit={(values, { setSubmitting }) => {
        let finalValues = values;
        let isEditMode = !!selectedLoc;
        if (isEditMode) {
          removeSameValues(finalValues, selectedLoc);
          finalValues["id"] = selectedLoc?.id;
        }
        createLocHandler(finalValues, isEditMode);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
    
        isSubmitting,
        /* and other goodies */
      }) => (
        <Modal
          destroyOnClose={true}
          style={{ top: 10 }}
          title="New location"
          visible={showCreateUpdateModal}
          onOk={handleSubmit}
          okText={selectedLoc ? "Edit Location" : "Create Location"}
          onCancel={cancelHandler}
        >
          <Form layout="vertical">
            <Form.Item
              validateStatus={touched.name && errors.name ? "error" : ""}
              help={errors.name}
              label="Name"
              required
              tooltip="e.g., Warehouse A"
            >
              <Input
                value={values.name}
                onBlur={handleBlur("name")}
                onChange={handleChange("name")}
                placeholder="Location Name Here"
              />
            </Form.Item>

            <Form.Item
              validateStatus={touched.gstin && errors.gstin ? "error" : ""}
              required
              help={errors.gstin}
              label="GSTIN"
              style={{
                display: "inline-block",
                width: "calc(50% - 50px)",
                marginRight: 20,
              }}
            >
              <Input
                value={values.gstin}
                onChange={handleChange("gstin")}
                onBlur={handleBlur("gstin")}
                placeholder="29GGGGG1314R9Z6 "
              />
            </Form.Item>

            <Form.Item
              validateStatus={
                touched.checkpoint && errors.checkpoint ? "error" : ""
              }
              help={errors.checkpoint}
              label="Checkpoint"
              tooltip="e.g., Processing"
              style={{
                display: "inline-block",
                width: "calc(50% - 12px)",
              }}
            >
              <Input
                value={values.checkpoint}
                onChange={handleChange("checkpoint")}
                onBlur={handleBlur("checkpoint")}
                placeholder="e.g., Processing / Packaging"
              />
            </Form.Item>

            <Form.Item
              validateStatus={touched.pincode && errors.pincode ? "error" : ""}
              help={errors.pincode}
              label="Pincode"
              required
              tooltip="e.g., 431005"
            >
              <Input
                value={values.pincode}
                onBlur={handleBlur("pincode")}
                onChange={handleChange("pincode")}
                placeholder="Enter Pincode of the location"
                style={{ width: 200 }}
                minLength={5}
                maxLength={5}
                type={"number"}
              />

              <Button
                onClick={() =>
                  getDataFromPincode(values.pincode, setFieldValue)
                }
              >
                Look Up Location
              </Button>
            </Form.Item>

            <Form.Item
              label="Address 1 (Street / Area)"
              tooltip="e.g., Gandhinagar"
              validateStatus={
                touched.address1 && errors.address1 ? "error" : ""
              }
              help={errors.address1}
            >
              <Input
                value={values.address1}
                onBlur={handleBlur("address1")}
                onChange={handleChange("address1")}
                placeholder="Address Line 1 here"
              />
            </Form.Item>

            <Form.Item
              label="Address 2 (Landmark / Zone)"
              tooltip="e.g., CBD Belapur"
              validateStatus={
                touched.address2 && errors.address2 ? "error" : ""
              }
              help={errors.address2}
            >
              <Input
                value={values.address2}
                onBlur={handleBlur("address2")}
                onChange={handleChange("address2")}
                placeholder="Address Line 2 Here"
              />
            </Form.Item>

            <Form.Item
              validateStatus={touched.city && errors.city ? "error" : ""}
              help={errors.city}
              label="City"
              tooltip="e.g., Navi Mumbai"
              style={{
                display: "inline-block",
                width: "calc(50% - 50px)",
                marginRight: 20,
              }}
            >
              <Input
                value={values.city}
                onBlur={handleBlur("city")}
                onChange={handleChange("city")}
                placeholder="Enter City Name"
              />
            </Form.Item>

            <Form.Item
              validateStatus={touched.state && errors.state ? "error" : ""}
              help={errors.state}
              label="State"
              tooltip="e.g., Maharashtra"
              style={{
                display: "inline-block",
                width: "calc(50% - 50px)",
                marginRight: 20,
              }}
            >
              <Input
                onBlur={handleBlur("state")}
                value={values.state}
                onChange={handleChange("state")}
                placeholder="Enter State Name"
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default CreateUpdateLocation;
