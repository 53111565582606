import { Table, Input, Divider, Button } from "antd";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

const Fill = ({ po, materials, onCancel, onOk }) => {
  const [materialData, setMaterialData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMaterialData([...po?.items]);
  }, []);

  const fillColumns = [
    {
      title: "Material ID",
      dataIndex: "material_id",
    },
    {
      title: "Material Name",
      dataIndex: "material_id",
      render: (value, row, index) =>
        materials.find((el) => el.id === value)?.name,
    },
    {
      title: "Total Inwarded Quantity",
      dataIndex: "quantity",
      render: (value, row) => value + " " + row.uom,
    },
    {
      title: "Current Pending Quantity",
      dataIndex: "pending",
      render: (value, record, index) => {
        return value + " " + record.uom;
      },
    },
    {
      title: "New Pending Quantity",
      // dataIndex: "pending",
      render: (value, record, index) => {
        return (
          parseFloat(record.pending) -
          (record.fill_quantity ? parseFloat(record.fill_quantity) : 0) +
          " " +
          record.uom
        );
      },
    },
    {
      title: "Fill Quantity",
      render: (val, record, index) => {
        return (
          <Input
            disabled={!record.pending}
            suffix={record.uom}
            value={record.fill_quantity}
            onChange={({ target: { value } }) => {
              let _arrCopy = [...materialData];
              _arrCopy[index]["fill_quantity"] = value;
              setMaterialData(_arrCopy);
            }}
          />
        );
      },
    },
  ];

  const handleFillClick = async () => {
    if (!materialData.find((el) => el?.fill_quantity)) {
      window.alert("No fill quantity provided");
      return null;
    } else if (
      materialData.find(
        (el) =>
          el.fill_quantity &&
          (parseFloat(el.fill_quantity) > parseFloat(el.quantity) ||
            parseFloat(el.fill_quantity) > parseFloat(el.pending))
      )
    ) {
      window.alert(
        "Fill quantity cannot be greater than the total or pending quantity"
      );
      return null;
    }


    try {
      const { data } = await axios.post("/api/purchase/po/fill", {
        po_id: po.id,
        fills: materialData,
      });
      onOk();
      setLoading(false);
    } catch (error) {
      setLoading(false);

      window.alert(error.message);
    }
  };

  return (
    <div>
      <Table
        scroll={{ x: true }}
        columns={fillColumns}
        dataSource={materialData}
        pagination={false}
      />

      <Divider className="mt-0.5 mb-0.5" />
      <div className="flex justify-end">
        <Button className="mr-0.5" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleFillClick}>
          Fill Order
        </Button>
      </div>
    </div>
  );
};

const mapState = (state) => {
  return {
    materials: state.data.materials,
  };
};

export default connect(mapState)(Fill);
