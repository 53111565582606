import { Form, Input, Button, Checkbox, Spin, Alert } from "antd";
import { connect } from "react-redux";
import { loginUserAction } from "../actions/authActions";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import agroTrustLogo from "../assets/images/logo.png";
import inventoLogo from "../assets/images/inventoLogo.png";
import inventoryManagement from "../assets/images/inventoryManagement.png";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

/**
 * @name LoginComponent
 * @param {Object} param0
 * @returns Something
 */
const Login = ({ loginUserAction, isLoggedIn, history, isLoading, error }) => {
  const [formErrors, setFormErrors] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/");
    }
    //eslint-disable-next-line
  }, [isLoggedIn]);

  const onFinish = async (values) => {
    const { email, password } = values;
    let _errorObj = {};
    if (!email || !password) {
      if (!email) _errorObj.email = "Email cannot be empty";
      if (!password) _errorObj.password = "Password cannot be empty";
      setFormErrors(_errorObj);
      return;
    }

    try {
      loginUserAction(
        {
          email,
          password,
        },
        history
      );
    } catch (error) {
      setFormErrors(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    window.alert("Failed:", errorInfo);
  };

  return (
    <Spin spinning={isLoading}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            className="flex justify-center mb-2"
            style={{ alignItems: "center" }}
          >
            <div style={{ marginLeft: 200 }}>
              <img
                src={agroTrustLogo}
                alt="AgroTrust Logo"
                style={{ maxHeight: 80 }}
              />
              <img src={inventoLogo} alt="Invento Logo" />
            </div>
          </div>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <div>
              <Form.Item
                {...tailLayout}
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              {error && (
                <Form.Item {...tailLayout}>
                  <Alert
                    style={{ marginBottom: 30 }}
                    message="Error!"
                    description={
                      error?.response?.data?.message || error?.message
                    }
                    type="error"
                  />
                </Form.Item>
              )}

              <Form.Item {...tailLayout}>
                <Button type="primary" block={true} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>

        <div
          style={{
            flex: 1,
            backgroundImage: `url(${inventoryManagement})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            minHeight: "70vh",
          }}
        ></div>
      </div>
    </Spin>
  );
};

const mapState = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.data.isLoading,
  error: state.data.error,
});
const mapDispatch = {
  loginUserAction,
};
export default connect(mapState, mapDispatch)(withRouter(Login));
