import { useState } from "react";
import axios from "axios";
import { Select, Form, Input, Modal, Button, message } from "antd";

function SubGroupsModal({
  getSubGroups,
  MAINGROUPS,
  isModalVisible,
  setModalVisible,
}) {
  const { Option } = Select;

  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [subgroup, setSubGroup] = useState();
  const [selectedGroup, setSelectedGroup] = useState();

  const validateInput = (subGroup) => {
    let error = "";
    if (!subGroup || subGroup === "") {
      error = "Please Enter Subgroup";
    } else if (!selectedGroup || selectedGroup === "") {
      error = "Please Select Group";
    }
    return error;
  };

  const cancelHandler = () => {
    setModalVisible(false);
  };

  const okHandler = () => {
    setConfirmLoading(true);

    const errors = validateInput(subgroup);

    const subGroupObject = {
      name: subgroup,
      main_group_id: selectedGroup || MAINGROUPS[0]?.id,
    };
    if (errors) {
      message.error(`${errors}`);
    }
    if (!errors) {
      createGroups(subGroupObject);
      setModalVisible(false);
      setSubGroup("");
      setSelectedGroup("");
    }
    setConfirmLoading(false);
  };

  const createGroups = async (subGroupObject) => {
    try {
      await axios.post("/api/subgroup/", subGroupObject);
      message.success("SubGroup added Successfully");
      getSubGroups();
    } catch (err) {
      message.error("Error Updating SubGroups");
    }
  };

  const handleSelect = (value) => {
    setSelectedGroup(value);
  };

  return (
    <Modal
      destroyOnClose={true}
      title="Add SubGroup"
      visible={isModalVisible}
      onOk={okHandler}
      onCancel={cancelHandler}
      confirmLoading={confirmLoading}
      footer={[
        <Button key="back" onClick={cancelHandler}>
          Cancel
        </Button>,
        <Button onClick={okHandler} type="primary" htmlType="submit">
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Select Group" required tooltip="e.g., 1122">
          <Select onChange={handleSelect}>
            {MAINGROUPS?.map((i) => {
              return (
                <Option key={i.name} value={i.id}>
                  {i.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Enter Subgroup" required tooltip="e.g., fluid">
          <Input
            value={subgroup}
            type="text"
            onChange={(e) => setSubGroup(e.target.value)}
            placeholder="Enter Subgroup Here"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SubGroupsModal;
