import React, { useEffect, useState } from "react";
import axios from "axios";
import { DatePicker, Select, Row, Col, Button, Table } from "antd";

import moment from "moment";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import useTableSearch from "../../../common/hooks/useTableSearch";
// import { roundValue } from '../../../common/functions/roundValue';

const ReportByOrganization = () => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [statsByNGO, setStatsByNGO] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");


  const fileheaders = [
    {label: "Partner Name", key: "partner_name"},
    {label: "Material Name", key: "material_name"},
    {label: "Rate of Material", key: "rate_per_material"},
    {label: "Total Sold Quantity", key: "total_quantity_sold"},
    {label: "Tax", key: "tax"},
    {label: "Total Amount", key: "total_amount_worth_sold"},

  ]

  const getStatsByNGO = async () => {
    try {
      setIsLoading(true);

      let url = `/api/report/gbPartnerAndProduct?start_date=${moment(
        startDate
      ).toISOString()}&last_date=${moment(endDate).toISOString()}`;
      console.log(url, "url for  stats");
      const { data } = await axios.get(url);
      setStatsByNGO(data);
      console.log(data, "this is data @@@@group by ngo");
    } catch (error) {
      window.alert(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getStatsByNGO();
  }, [startDate, endDate]);

  const columns = [
    {
      title: "Partner Name",
      dataIndex: "partner_name",
      ...useTableSearch("partner_table"),
    },
    {
      title: "Material Name",
      dataIndex: "material_name",
      ...useTableSearch("name"),
    },
    {
      title: "Rate of material",
      dataIndex: "rate_per_material",
    },
    {
      title: "Total quantity sold",
      dataIndex: "total_quantity_sold",
    },

    {
      title: "Tax",
      dataIndex: "tax",
    },

    {
      title: "Total amount Sold",
      dataIndex: "total_amount_worth_sold",
      render: (value) => `₹ ${value?.toFixed(2)}`,
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <DatePicker.RangePicker
          onCalendarChange={(dates, dateStrings, range) => {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
          }}
          allowClear={false}
          disabledDate={(d) => !d || d.isAfter(moment())}
          value={[startDate, endDate]}
        />

        <div>
          <Button type="primary" style={{ marginLeft: "0.5rem" }}>
            <CSVLink  headers={fileheaders} data={statsByNGO}  filename={"SalesReport.csv"}>Export</CSVLink>
          </Button>
        </div>


      </div>

      <div style={{ marginTop: "1rem" }}>
        <Table
          scroll={{
            x: true,
          }}
          columns={columns}
          dataSource={statsByNGO}
        />
      </div>
    </div>
  );
};




export default ReportByOrganization;
