import axios from "axios";
import {
  FETCH_INITIATE,
  // FETCH_SUCCESS,
  FETCH_ERROR,
  GET_PURCHASE_ORDERS_LIST,
  GET_SUPPLIER_LIST,
  GET_MATERIAL_LIST,
  GET_SALES_ORDERS_LIST,
  GET_CUSTOMER_LIST,
  GET_SKU_LIST,
  GET_TAX_LIST,
  GET_GROUPS_LIST,
  GET_SUBGROUPS_LIST,
  GET_TRANSPORTER_LIST,
  GET_LOCATIONS_LIST,
  SET_LOCATION,
  FETCH_SUCCESS,
  GET_SUPPLIER_TYPE,
  GET_PAYMENT_TERMS,
  GET_CUSTOMER_TYPE,
  GET_ITEM_PRICE_LIST,
} from "./actionTypes";

/**
 * Makes axios calls to proper endpoints and returns the response from them
 * @param {String} type - One of the predefined types
 * @returns {Object} - with response data
 */
export const getList = (type) => async (dispatch) => {
  await dispatch({ type: FETCH_INITIATE });
  try {
    let _url, _type;

    switch (type) {
      case "purchaseOrders":
        _type = GET_PURCHASE_ORDERS_LIST;
        _url = `/api/purchase/po/list`;
        break;
      case "customers":
        _type = GET_CUSTOMER_LIST;
        _url = `/api/partners/partner/list?role=customer`;
        break;
      case "salesOrders":
        _type = GET_SALES_ORDERS_LIST;
        _url = `/api/sales/so/list`;
        break;
      case "transporters":
        _type = GET_TRANSPORTER_LIST;
        _url = `/api/partners/partner/list?role=transporter`;
        break;
      case "suppliers":
        _type = GET_SUPPLIER_LIST;
        _url = `/api/partners/partner/list?role=supplier`;
        break;
      case "skus":
        _type = GET_SKU_LIST;
        _url = `/api/goods/sku/list`;
        break;
      case "materials":
        _type = GET_MATERIAL_LIST;
        _url = `/api/goods/material/list`;
        break;
      case "locations":
        _type = GET_LOCATIONS_LIST;
        _url = `/api/location/list`;
        break;
      case "taxes":
        _type = GET_TAX_LIST;
        _url = "/api/tax/list";
        break;
      case "groups":
        _type = GET_GROUPS_LIST;
        _url = "/api/group/";
        break;
      case "subgroups":
        _type = GET_SUBGROUPS_LIST;
        _url = "/api/subgroup/";
        break;
      case "supplierType":
        _type = GET_SUPPLIER_TYPE;
        _url = "/api/partner_type?type=supplier";
        break;
      case "customerType":
        _type = GET_CUSTOMER_TYPE;
        _url = "/api/partner_type?type=customer";
        break;
      case "paymentTerms":
        _type = GET_PAYMENT_TERMS;
        _url = "/api/term";
        break;
      case "itemPrices":
        _type = GET_ITEM_PRICE_LIST;
        _url = "/api/price";
        break;
      default:
        break;
    }
    dispatch({
      type: FETCH_INITIATE,
    });
    const response = await axios.get(_url);
    dispatch({
      type: FETCH_SUCCESS,
    });
    return dispatch({
      type: _type,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    return dispatch({ type: FETCH_ERROR, error });
  }
};

export const getCustomersWithCallback =
  (id, callbackFn) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/api/partners/partner/list?role=customer`
      );
      dispatch({
        type: GET_CUSTOMER_LIST,
        payload: {
          data,
        },
      });
      callbackFn("customer_id", id);
    } catch (error) {
      dispatch({ type: FETCH_ERROR, error });
    }
  };

export const setLocation = (locationId) => {
  return {
    type: SET_LOCATION,
    payload: {
      data: locationId,
    },
  };
};
