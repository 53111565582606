import { Tooltip } from "antd";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  StopFilled,
} from "@ant-design/icons";



/**
 * Will return a tooltip with icon for the Invoice Section
 * @param {String} value - Enum: NA | Completed | Partially Filled
 * @returns {JSX.Element} - Tooltip with the proper icon as per the parameter passed
 */
const fillStatusIcon = (value) => {
  switch (value) {
    case "NA":
      return (
        <Tooltip title={value}>
          <ExclamationCircleFilled style={{ color: "maroon", fontSize: 25 }} />
        </Tooltip>
      );
    case "Completed":
      return (
        <Tooltip title={value}>
          <CheckCircleFilled style={{ color: "green", fontSize: 25 }} />
        </Tooltip>
      );
    case "Partially Filled":
      return (
        <Tooltip title={value}>
          <StopFilled style={{ color: "#adad4b", fontSize: 25 }} />
        </Tooltip>
      );
    default:
      break;
  }
};

/**
 * Will return a tooltip with icon for the Invoice Section
 * @param {String} value - Enum: active | inactive
 * @returns {JSX.Element} - Tooltip with the proper icon as per the parameter passed
 */
export const inventoryStatusIcon = (value) => {
  switch (value) {
    case "active":
      return (
        <Tooltip title={value}>
          <CheckCircleFilled style={{ color: "green", fontSize: 25 }} />
        </Tooltip>
      );
    case "inactive":
      return (
        <Tooltip title={value}>
          <ExclamationCircleFilled style={{ color: "#b8b877", fontSize: 25 }} />
        </Tooltip>
      );
    default:
      break;
  }
};

export default fillStatusIcon;
