const getDataFromPincodeHandler = async (pincode) => {
  try {
    const response = await fetch(
      "https://api.postalpincode.in/pincode/" + pincode
    );
    const pincodes = await response.json();
    const pincodeData = pincodes[0]?.PostOffice?.[0] || "";
    return pincodeData;
  } catch (error) {
    window.alert(error.message);
  }
};

export default getDataFromPincodeHandler;
