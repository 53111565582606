import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { inventoryStatusIcon } from "../../../common/components/statusIcons";
// import { transporter_TYPES } from "../customers/constants";
import CreateOrUpdate from "../partners.createOrUpdateModal";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { isEmpty } from "../../../common/functions/validateInputs";
import getAddress from "../../../common/functions/getAddress";

const TransporterMain = () => {
  const [data, setData] = useState([]);

  const [selectedTransporter, setSelectedTransporter] = useState(null);

  const [showCuModal, setShowCuModal] = useState(false);

  useEffect(() => {
    listAPICall();
  }, []);

  const listAPICall = async () => {
    try {
      const { data } = await axios.get(
        `/api/partners/partner/list?role=transporter`
      );
      setData(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const columns = [
    {
      title: "Transporter ID",
      dataIndex: "id",
      width: 50,
      ...useTableSearch("id"),
    },
    {
      title: "Transporter Name",
      dataIndex: "name",
      ...useTableSearch("name"),
      render: (value) => <b>{value}</b>,
    },
    {
      title: "Addresses",
      dataIndex: "addresses",
      render: (addrArray, record, index) => {
        const { id, address1, address2, city, state } = addrArray[0] || "";
        return (
          <div>{id && getAddress({ address1, address2, city, state })}</div>
        );
      },
    },

    // { title: "City", dataIndex: "city" },
    // {
    //   title: "State",
    //   dataIndex: "state",
    //   filters: STATES,
    //   filterMultiple: true,
    //   onFilter: (value, record) => record.state.indexOf(value) === 0,
    // },
    // { title: "Pincode", dataIndex: "pincode" },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => inventoryStatusIcon(value),
    },
    {
      title: "Actions",
      render: (value, record) => {
        return (
          <Tooltip title="Edit">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedTransporter(record);
                setShowCuModal(true);
              }}
              danger
            />
          </Tooltip>
        );
      },
    },
  ];

  const createOrUpdateHandler = async (transporterObject, isUpdate = false) => {
    transporterObject = { ...transporterObject, role: "transporter" };
    const isEditMode = selectedTransporter?.id;
    try {
      const url = `/api/partners/partner/${isEditMode ? "edit" : "create"}`;
      if (isEditMode) transporterObject["id"] = selectedTransporter.id;
      await axios.post(url, transporterObject);
      setShowCuModal(false);
      message.success("Transporter created successfully");
      await listAPICall();
    } catch (error) {
      message.error(`${error.message} - ${error.response?.data?.message}`);
    }
    // let _mandatoryFields = {
    //   name: transporterObject.name,
    //   role: transporterObject.role,
    // };

    // let error = isEmpty(_mandatoryFields);

    // try {
    //   const url = `/api/partners/partner/${
    //     selectedTransporter?.id ? "edit" : "create"
    //   }`;
    //   if (error) {
    //     setShowCuModal(true);
    //     message.error(`${error}`);
    //   }
    //   if (!error) {
    //     await axios.post(url, transporterObject);
    //     setShowCuModal(false);
    //     message.success("Transporter created successfully");
    //   }
    //   await listAPICall();
    // } catch (error) {
    //   window.alert(error.message);
    // }
  };

  const cancelHandler = () => {
    setShowCuModal(false);
    setSelectedTransporter(null);
  };

  return (
    <>
      <div className="flex justify-between mb-1">
        <div></div>
        <Button type="primary" onClick={() => setShowCuModal(true)}>
          + New Transporter{" "}
        </Button>
      </div>
      <Table columns={columns} dataSource={data} />

      <Modal
        destroyOnClose
        title={
          selectedTransporter?.id
            ? "Update transporter Details"
            : "Create a new transporter"
        }
        footer={null}
        style={{ top: 2 }}
        visible={showCuModal}
        // onOk={(_transporterObject) => createOrUpdateHandler(_transporterObject)}
        onCancel={cancelHandler}
        width={"100%"}
      >
        {showCuModal && (
          <CreateOrUpdate
            partnerRole="Transporter"
            selectedPartner={selectedTransporter}
            onOk={createOrUpdateHandler}
            onCancel={cancelHandler}
          />
        )}
      </Modal>
    </>
  );
};

export default TransporterMain;
