const getAddress = (record) => {
  let addr = "";
  const { address1, address2, city, country, pincode, state } = record;

  const withComma = (string) => {
    if (string) addr += `${string}, `;
  };

  withComma(address1);
  withComma(address2);
  withComma(city);
  withComma(state);
  withComma(country);
  withComma(pincode);
  addr = addr.trim()
  addr = addr.replace(/^,+|,+$/g, '');
  return addr;
};

export default getAddress;
