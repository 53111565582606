import { List, Button, Input, Form, Modal } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { getList } from "../../../actions/dataActions";
import CreateUpdateLocation from "./CreateUpdateLocation";
import getAddress from "../../../common/functions/getAddress";

const LocationsEdit = ({ locations, getList }) => {
  const [selectedLoc, setSelectedLoc] = useState(null);
  const [showCreateUpdateModal, setShowCreateUpdate] = useState(false);

  const createLocHandler = async (locationObject, updateMode = false) => {
    try {
      const response = await axios.post(
        "/api/location/" + (updateMode ? "edit" : "create"),
        locationObject
      );

      getList("locations");
    } catch (error) {
      window.alert(error.message);
    } finally {
      setShowCreateUpdate(false);
    }
  };

  const editLocationHandler = (loc) => {
    setSelectedLoc(loc);
    setShowCreateUpdate(true);
  };

  const cancelHandler = () => {
    setSelectedLoc(null);
    setShowCreateUpdate(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button type="primary" onClick={() => setShowCreateUpdate(true)}>
          <PlusOutlined /> Add Location
        </Button>
      </div>

      <List
        itemLayout="horizontal"
        dataSource={locations}
        bordered
        renderItem={(loc) => (
          <List.Item style={{ marginBottom: 5 }}>
            <List.Item.Meta
              title={<b>{loc.name}</b>}
              description={
                <div>
                  {getAddress(loc)}
                  {/* {loc.address1}, {loc.address2}, {loc.city}, {loc.state}, {loc.pincode} */}
                </div>
              }
            />
            <Button type="default" onClick={() => editLocationHandler(loc)}>
              <EditOutlined />
              Edit Location Details
            </Button>
          </List.Item>
        )}
      />

      {showCreateUpdateModal && (
        <CreateUpdateLocation
          selectedLoc={selectedLoc}
          showCreateUpdateModal={showCreateUpdateModal}
          createLocHandler={createLocHandler}
          cancelHandler={cancelHandler}
        />
      )}
    </>
  );
};

const mapState = (state) => ({
  locations: state.data.locations,
});
const mapDispatch = {
  getList,
};

const Locations = connect(mapState, mapDispatch)(LocationsEdit);

export default Locations;
