import React, { useEffect, useState, useRef } from "react";
import { Table, Tooltip, Button, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { inventoryStatusIcon } from "../../../common/components/statusIcons";
import CreateUpdateSkus from "./goods.skus.createUpdateModal";
import useTableSearch from "../../../common/hooks/useTableSearch";

const SKUSummary = () => {
  const [data, setData] = useState([]);
  const [showCuModal, setShowCuModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [materials, setMaterials] = useState([]);

  const [selectedSku, setSelectedSku] = useState({});

  useEffect(() => {
    listAPICall();
  }, []);

  const listAPICall = async () => {
    try {
      const skuCall = axios.get(`/api/goods/sku/list`);
      const matCall = axios.get(`/api/goods/material/list`);

      let [sku, mat] = await axios.all([skuCall, matCall]);

      setData(sku.data);
      setMaterials(mat.data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const columns = [
    { title: "SKU ID", dataIndex: "id", ...useTableSearch("id") },
    {
      title: "SKU Name",
      dataIndex: "sku",
      ...useTableSearch("sku"),
      render: (value) => <b>{value}</b>,
    },
    {
      title: "Display Name",
      dataIndex: "display",
      ...useTableSearch("display"),
    },
    { title: "Quantity", dataIndex: "quantity" },
    { title: "UoM", dataIndex: "uom" },
    { title: "Eq. Weight (kg)", dataIndex: "weight" },
    {
      title: "Tax Rate",
      dataIndex: "tax",
      render: (value) => value + "%",
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => inventoryStatusIcon(value),
    },
    {
      title: "Actions",
      render: (value, record) => {
        return (
          <Tooltip title="Edit">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              // onClick={() => handleCard(record)}
              onClick={() => {
                setSelectedSku(record);
                setShowCuModal(true);
              }}
              danger
            />
          </Tooltip>
        );
      },
    },
  ];

  const handleCancel = () => {
    setSelectedSku({});
    setShowCuModal(false);
  };

  const handleCreateUpdate = async (_finalObject) => {
    try {
      setIsLoading(true);
      const _url = `/api/goods/sku/` + (_finalObject?.id ? `edit` : `create`);
      const { data } = await axios.post(_url, _finalObject);
      setIsLoading(false);
      listAPICall();
      setShowCuModal(false);
    } catch (error) {
      message.error(`${error.message} - ${error.response?.data?.message}`);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div>List of SKU's in the system</div>
        <Button
          type="primary"
          onClick={() => {
            setShowCuModal(true);
          }}
        >
          + New SKU{" "}
        </Button>
      </div>

      <br />
      <Table columns={columns} dataSource={data} />
      <Modal
        title={selectedSku?.id ? "Update SKU" : "Create SKU"}
        destroyOnClose
        visible={showCuModal}
        confirmLoading={isLoading}
        footer={null}
        onCancel={handleCancel}
        width={"100%"}
        style={{ top: 2 }}
      >
        <CreateUpdateSkus
          selectedSku={selectedSku}
          onOk={handleCreateUpdate}
          onCancel={handleCancel}
          materials={materials}
        />
      </Modal>
    </>
  );
};

export default SKUSummary;
