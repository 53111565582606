import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";
import { formatGroups } from "../../../common/functions/formatGroups";

import { inventoryStatusIcon } from "../../../common/components/statusIcons";
import useTableSearch from "../../../common/hooks/useTableSearch";
import CreateOrUpdateMaterials from "./materials.createOrUpdate";

const MaterialSummary = ({
  getGroups,
  getSubGroups,
  MAINGROUPS,
  SUBGROUPS,
}) => {
  const [data, setData] = useState([]);

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  useEffect(() => {
    listAPICall();
    getGroups();
    getSubGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listAPICall = async () => {
    const { data } = await axios.get(`/api/goods/material/list`);
    setData(data);
  };

  const columns = [
    {
      title: "Material ID",
      dataIndex: "id",
      ...useTableSearch("id"),
      sorter: (x, y) => x.id - y.id,
      width: 150,
    },
    {
      title: "Material Name",
      dataIndex: "name",
      ...useTableSearch("name"),
      render: (value) => <b>{value}</b>,
    },
    {
      title: "Sub Group",
      dataIndex: "subgroup",
      filters: formatGroups(SUBGROUPS),
      filterMultiple: true,
      onFilter: (value, record) => record.subgroup.indexOf(value) === 0,
    },
    {
      title: "Main Group",
      dataIndex: "maingroup",
      filters: formatGroups(MAINGROUPS),
      filterMultiple: true,
      onFilter: (value, record) => record.maingroup.indexOf(value) === 0,
    },
    { title: "UoM", dataIndex: "uom" },
    {
      title: "Tax Rate",
      dataIndex: "tax",
      render: (value) => value + "%",
      sorter: (x, y) => x.tax - y.tax,
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => inventoryStatusIcon(value),
    },
    {
      title: "Actions",
      render: (value, record) => {
        return (
          <Tooltip title="Edit">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedMaterial(record);
                setVisible(true);
              }}
              danger
            />
          </Tooltip>
        );
      },
    },
  ];

  const handleOk = async (materialObject, isEditMode = false) => {
    const endpoint = "/api/goods/material/" + (isEditMode ? "edit" : "create");
    setConfirmLoading(true);
    try {
      await axios.post(endpoint, materialObject);
      setVisible(false);
      setSelectedMaterial(null);
      listAPICall();
    } catch (error) {
      window.alert(error.message);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setSelectedMaterial(null);
    setVisible(false);
  };

  return (
    <>
      <div className="flex justify-between">
        <div>List of materials in the system</div>
        <Button type="primary" onClick={() => setVisible(true)}>
          + New Material{" "}
        </Button>
      </div>

      <br />
      <Table columns={columns} dataSource={data} />

      {visible && (
        <CreateOrUpdateMaterials
          visible={visible}
          selectedMaterial={selectedMaterial}
          confirmLoading={confirmLoading}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

const mapState = (state) => ({
  MAINGROUPS: state.data.grouplist,
  SUBGROUPS: state.data.subgrouplist,
});

const mapDispatch = {
  getGroups: () => getList("groups"),
  getSubGroups: () => getList("subgroups"),
};

export default connect(mapState, mapDispatch)(MaterialSummary);
