import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { inventoryStatusIcon } from "../../../common/components/statusIcons";
import CreateOrUpdate from "../partners.createOrUpdateModal";
import { CUSTOMER_TYPES } from "../constants";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { isEmpty } from "../../../common/functions/validateInputs";
import getAddress from "../../../common/functions/getAddress";
import { getList } from "../../../actions/dataActions";
import { connect } from "react-redux";

const CustomerSummary = ({ getCustomerType, customerType }) => {
  const [data, setData] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [showCuModal, setShowCuModal] = useState(false);

  useEffect(() => {
    listAPICall();
    getCustomerType();
  }, []);

  const listAPICall = async () => {
    try {
      const { data } = await axios.get(
        `/api/partners/partner/list?role=customer`
      );
      setData(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const columns = [
    {
      title: "#",
      // dataIndex: "id",
      render: (v, r, index) => {
        return index + 1;
      },
      width: 50,
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      ...useTableSearch("name"),
      render: (value) => <b>{value}</b>,
    },
    {
      title: "Type",
      dataIndex: "type",
      filters: CUSTOMER_TYPES,
      filterMultiple: true,
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },

    {
      title: "Addresses",
      dataIndex: "addresses",
      render: (addrArray, record, index) => {
        const { id, address1, address2, city, state } = addrArray[0] || "";
        return (
          <div>
            {id && getAddress({ address1, address2, city, state })}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => inventoryStatusIcon(value),
    },
    {
      title: "Actions",
      render: (value, record) => {
        return (
          <Tooltip title="Edit">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedCustomer(record);
                setShowCuModal(true);
              }}
              danger
            />
          </Tooltip>
        );
      },
    },
  ];

  const createOrUpdateHandler = async (customerObject, isUpdate = false) => {
    console.log(customerObject, "@@@@105")
    customerObject = { ...customerObject, role: "customer" };
    const isEditMode = selectedCustomer?.id;
    try {
      const url = `/api/partners/partner/${isEditMode ? "edit" : "create"}`;
      if (isEditMode) customerObject["id"] = selectedCustomer.id;
      await axios.post(url, customerObject);
      setShowCuModal(false);
      message.success("Customer created successfully");
      await listAPICall();
    } catch (error) {
      message.error(`${error.message} - ${error.response?.data?.message}`);
    }



    // let _mandatoryFields = {
    //   name: customerObject.name,
    //   role: customerObject.role,
    // };

    // let error = isEmpty(_mandatoryFields);

    // try {
    //   const url = `/api/partners/partner/${
    //     selectedCustomer?.id ? "edit" : "create"
    //   }`;
    //   if (error) {
    //     setShowCuModal(true);
    //     message.error(`${error}`);
    //   }
    //   if (!error) {
    //     await axios.post(url, customerObject);
    //     setShowCuModal(false);
    //     message.success("Customer created successfully");
    //   }
    //   await listAPICall();
    // } catch (error) {
    //   window.alert(error.message);
    // }
  };

  const cancelHandler = () => {
    setShowCuModal(false);
    setSelectedCustomer(null);
  };

  return (
    <>
      <div className="flex justify-between mb-1">
        <div></div>
        <Button type="primary" onClick={() => setShowCuModal(true)}>
          + New Customer{" "}
        </Button>
      </div>
      <Table columns={columns} dataSource={data} />

      <Modal
        destroyOnClose
        title={
          selectedCustomer?.id
            ? "Update Customer Details"
            : "Create a new Customer"
        }
        footer={null}
        style={{ top: 2 }}
        visible={showCuModal}
        // onOk={(_customerObject) => createOrUpdateHandler(_customerObject)}
        onCancel={cancelHandler}
        width={"100%"}
      >
        {showCuModal && (
          <CreateOrUpdate
            partnerRole="Customer"
            selectedPartner={selectedCustomer}
            onOk={createOrUpdateHandler}
            onCancel={cancelHandler}
          />
        )}
      </Modal>
    </>
  );
};

const mapState = (state) => {
  return {
    customerType: state.data.customerType,
  };
};

const mapDispatch = {
  getCustomerType: () => getList("customerType"),
};

export default connect(mapState, mapDispatch)(CustomerSummary);
