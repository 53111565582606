import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  Statistic,
  Row,
  Col,
  Button,
  DatePicker,
  Spin,
  Select,
} from "antd";
import moment from "moment";
import SkuAlert from "./alert/alert.skus";
import MaterialAlert from "./alert/alert.materials";
import { connect } from "react-redux";
import { setLocation } from "../../actions/dataActions";

const Home = (props) => {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [locationId, setLocationId] = useState(null);

  useEffect(() => {
    document.title = "Home - Invento";
  }, []);

  const getStats = async () => {
    try {
      setIsLoading(true);
      let url = `/api/stats?from=${moment(startDate).toISOString()
      }&to=${moment(endDate).toISOString()}`;
      console.log(url, "url")
      if (locationId) url += `&location=${locationId}`;
      const { data } = await axios.get(url);

      setSummary(data);
    } catch (error) {
      window.alert(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getStats();
    //eslint-disable-next-line
  }, []);
   
  // const _FORMAT = "DD-MMM-YY";

  return (
    <div>
      <div className="flex items-center mb-2 justify-center">
        <div className="mb-0" style={{ fontSize: 20, fontWeight: "bold" }}>
          Showing Statistics for &nbsp;
        </div>
        <DatePicker.RangePicker
          onCalendarChange={(dates, dateStrings, range) => {
            
            setStartDate(dates[0]);
            setEndDate(dates[1]);
          
          }}
          allowClear={false}
          disabledDate={(d) => !d || d.isAfter(moment())}
          value={[startDate, endDate]}
          
        />{" "}
        &nbsp;
        <Select
          allowClear
          placeholder="Filter by location"
          style={{ width: 300 }}
          value={locationId}
          onChange={(v) => {
            setLocationId(v);
          }}
        >
          {props.locations.map((l) => {
            return (
              <Select.Option key={l.id} value={l.id}>
                {l.name}
              </Select.Option>
            );
          })}
        </Select>
        <Button onClick={getStats} type="primary">
          Show
        </Button>
      </div>

      <div>
        {isLoading ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : (
          <>
            <div>
              <div className="flex justify-between">
                <SalesOrders data={summary?.so} />
                <PurchaseOrders data={summary?.po} />
                <MaterialOrders data={summary?.mo} />
              </div>
            </div>

            <div className="mt-2">
              <h3>Low stock alert</h3>

              <div
                className="flex justify-between"
                style={{ alignItems: "baseline" }}
              >
                <SkuAlert history={props.history} _data={summary?.sku_alert} />

                <MaterialAlert
                  history={props.history}
                  _data={summary?.material_alert}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {/* <div>{!isLoading && JSON.stringify(summary)}</div> */}
    </div>
  );
};

const SalesOrders = ({ data }) => {
  const { total_amount, total_count } = data || 0;
  return (
    <Card
      size="small"
      title={<b>Sales Orders</b>}
      className="flex-1"
      style={{ marginRight: 10 }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Orders in the span" value={`${total_count}`} />
        </Col>
        <Col span={12}>
          <Statistic
            title="Worth of orders"
            value={`Rs ${total_amount?.toLocaleString("en-IN") || 0}`}
            precision={2}
          />
        </Col>
      </Row>
    </Card>
  );
};

const PurchaseOrders = ({ data }) => {
  const { total_amount, total_count } = data || 0;
  return (
    <Card
      size="small"
      title={<b>Purchase Orders</b>}
      className="flex-1"
      style={{ marginRight: 10 }}
    >
      {/* {JSON.stringify(data)} */}
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Orders in the span" value={`${total_count}`} />
        </Col>
        <Col span={12}>
          <Statistic
            title="Worth of orders"
            value={`Rs ${total_amount?.toLocaleString("en-IN") || 0}`}
            precision={2}
          />
        </Col>
      </Row>
    </Card>
  );
};

const MaterialOrders = ({ data }) => {
  const { total_amount, total_count } = data || 0;

  return (
    <Card
      size="small"
      title={<b>Material Orders</b>}
      className="flex-1"
      style={{ marginRight: 10 }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Orders in the span" value={`${total_count}`} />
        </Col>
        <Col span={12}>
          <Statistic
            title="Worth of orders"
            value={`Rs ${total_amount?.toLocaleString("en-IN") || 0}`}
            precision={2}
          />
        </Col>
      </Row>
    </Card>
  );
};

const mapState = (state) => {
  return {
    locations: state.data?.locations || [],
    selectedLocationId: state.data?.selectedLocation?.id,
  };
};

const mapDispatch = {
  setLocation,
};

export default connect(mapState, mapDispatch)(Home);
