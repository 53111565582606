import React, { useEffect } from "react";
import { Tabs } from "antd";
import { CodeSandboxOutlined, BarcodeOutlined } from "@ant-design/icons";
import SKUSummary from "./skus/goods.skus.main";
import MaterialSummary from "./materials/materials.main";
const { TabPane } = Tabs;

const GoodsScreen = () => {
  useEffect(() => {
    document.title = "Goods - Invento";
  }, []);

  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <span>
            <CodeSandboxOutlined />
            Materials
          </span>
        }
        key="1"
      >
        <MaterialSummary />
      </TabPane>
      <TabPane
        tab={
          <span>
            <BarcodeOutlined />
            SKUs
          </span>
        }
        key="2"
      >
        <SKUSummary />
      </TabPane>
    </Tabs>
  );
};

export default GoodsScreen;
