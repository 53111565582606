import React, { useEffect } from "react";
import { Tabs } from "antd";
import { DiffOutlined, CarryOutOutlined } from "@ant-design/icons";
import PurchaseOrders from "./purchase-orders/purchaseOrders.main";
import GoodsReceivedNotes from "./goods-received-notes/goodsReceivedNotes.main";
const { TabPane } = Tabs;

const PurchaseScreen = () => {

  
  useEffect(() => {
    document.title = "Purchase - Invento";
  }, []);


  return (
    <Tabs destroyInactiveTabPane={true} defaultActiveKey="1">
      <TabPane
        tab={
          <span>
            <DiffOutlined />
            Purchase Orders
          </span>
        }
        key="1"
      >
        <PurchaseOrders />
      </TabPane>
      <TabPane
        tab={
          <span>
          <CarryOutOutlined />
            Goods Received Notes
          </span>
        }
        key="2"
      >
        <GoodsReceivedNotes />
      </TabPane>
    </Tabs>
  );
};

export default PurchaseScreen;
