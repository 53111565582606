import { combineReducers } from "redux";
import authReducer from "./authReducer";
import uiReducer from "./uiReducer";
import dataReducer from "./dataReducer";


/** @module Reducers **/

/**
 * 
 * `combinedReducers` combines the reducers, and assigns the states returned by them to their proper keys
 */

const combinedReducers = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  data: dataReducer
});

export default combinedReducers;
