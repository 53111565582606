import { Button, Table, Modal, Input, Space, message } from "antd";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  EditOutlined,
  FunnelPlotOutlined,
  CloseCircleTwoTone,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { Tooltip, Select } from "antd";
import { EllipsisOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import MoCreateEditModal from "./makeOrder.createOrEdit";
import FillModalContent from "./makeOrder.fill";
import { DATE_TIME_FORMAT, FILL_STATUS } from "../../../assets/constants";
import fillStatusIcon from "../../../common/components/statusIcons";
import Highlighter from "react-highlight-words";
import LocationSelect from "../../../common/components/locationSelect";
import { connect } from "react-redux";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { isEmpty } from "../../../common/functions/validateInputs";
import { roundValue } from "../../../common/functions/roundValue";

const getIconsFromStatus = (status) => {
  switch (status) {
    case "Approved":
      return (
        <Tooltip title={status}>
          <CheckCircleTwoTone style={{ fontSize: 30 }} twoToneColor="#52c41a" />
        </Tooltip>
      );
    case "Awaiting":
      return (
        <Tooltip title={status}>
          <EllipsisOutlined style={{ fontSize: 30 }} twoToneColor="#52c41a" />
        </Tooltip>
      );
    case "Rejected":
      return (
        <Tooltip title={status}>
          <CloseCircleTwoTone style={{ fontSize: 30 }} twoToneColor="#eb2f96" />
        </Tooltip>
      );
    default:
      break;
  }
};

const MakeOrder = ({ changeTab, locationId, locationName }) => {
  const [moList, setMoList] = useState([]);
  const [localMakeOrders, setLocalMakeOrders] = useState([]);
  const [taskList, setTaskList] = useState([]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showFillModal, setShowFillModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [skuList, setSkuList] = useState([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // state for search

  const onTableRowExpand = (expanded, record) => {
    var keys = [];
    if (expanded) {
      keys.push(record.id?.toString());
    }
    setExpandedRowKeys(keys);
  };

  const moCols = [
    {
      title: "M.Order #",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      ...useTableSearch("id"),
    },

    {
      title: "SKU",
      width: 250,
      dataIndex: "sku_id",
      render: (text) => <b>{skuList?.find((el) => el.sku_id === text)?.sku}</b>,
    },

    {
      title: "Created Date",
      dataIndex: "created_date",
      defaultSortOrder: "descend",
      render: (value) => {
        return moment(value).format(DATE_TIME_FORMAT);
      },
      sorter: (x, y) =>
        moment(x?.created_date).unix() - moment(y?.created_date).unix(),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      render: (text) => {
        return moment(text).format(DATE_TIME_FORMAT);
      },
      sorter: (x, y) => moment(x?.due_date).unix() - moment(y?.due_date).unix(),
    },
    {
      title: "Total Quantity",
      dataIndex: "total_quantity",
      render: (text) => text + " Pc",
    },

    {
      title: "Material Cost",
      dataIndex: "material_cost",
      render: (value) => "₹ " + roundValue(value),
    },
    {
      title: "Labour Cost",
      dataIndex: "labour_cost",
      render: (value) => "₹ " + roundValue(value),
    },
    {
      title: "Make Status",
      dataIndex: "fill_status",
      filters: FILL_STATUS,
      onFilter: (value, record) => record?.fill_status?.indexOf(value) === 0,
      render: (value) => fillStatusIcon(value),
    },
    {
      title: "Actions",
      width: 150,
      render: (value, record) => {
        return (
          <>
            <div className="flex">
              <Tooltip title="Edit">
                <Button
                  disabled={record?.fill_status === "Completed"}
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedOrder(record);
                    setShowEditModal(true);
                  }}
                  danger
                />
              </Tooltip>
              <Tooltip title="Fill">
                <Button
                  disabled={record?.fill_status === "Completed"}
                  onClick={() => {
                    setSelectedOrder(record);
                    setShowFillModal(true);
                  }}
                  type="primary"
                  shape="circle"
                  icon={<FunnelPlotOutlined />}
                />
              </Tooltip>

              <Tooltip title="Download">
                <Button
                  danger
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    window.open(`/invoices/make/${record.id}`, "_blank");
                  }}
                />
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  const createModalClick = () => {
    setShowCreateModal(true);
  };

  useEffect(() => {
    getMakeOrders();
    getSkuList();
    getTaskList();
  }, []);

  useEffect(() => {
    if (moList.length) {
      const filtered = moList.filter((el) => el.location_id === locationId);
      setLocalMakeOrders(filtered);
    }
  }, [moList, locationId]);

  const getMakeOrders = async () => {
    try {
      const response = await axios.get("/api/make/mo/list");
      setMoList(response.data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const getSkuList = async () => {
    try {
      const response = await axios.post("/api/inventory/sku/list", {
        location_id: locationId,
        inv_date: new Date().toISOString(),
      });
      setSkuList(response.data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const getTaskList = async () => {
    try {
      const { data } = await axios.get("/api/make/task/list");
      setTaskList(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const deleteMakeOrder = async (moId) => {
    try {
      const { data } = await axios.delete(`/api/make/mo/${moId}`);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const createOrUpdateOrder = async (_finalObj) => {
    let _mandatoryFields = {
      duedate: _finalObj.due_date,
      SkuId: _finalObj.sku_id,
      quantity: _finalObj.total_quantity,
    };

    let error = isEmpty(_mandatoryFields);

    if (error) {
      setShowCreateModal(true);
      message.error(`${error}`);
    }

    try {
      if (!error) {
        const { data } = await axios.post(
          `/api/make/mo/${_finalObj?.mo_id ? "edit" : "create"}`,
          _finalObj
        );
        _finalObj?.mo_id === "create"
          ? message.success("Make order created successfully")
          : message.success("Make order updated successfully");
        setShowCreateModal(false);
      }
    } catch (error) {
      window.alert(error);
    }
  };

  const fillOrder = async (_finalObj) => {
    try {
      const { data } = await axios.post(`/api/make/mo/fill`, _finalObj);
    } catch (error) {
      window.alert(error);
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <LocationSelect />

        <Button type="primary" onClick={createModalClick}>
          + New MO for {locationName}
        </Button>
      </div>
      <br />
      <div>
        <Table
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>
                <b>Comment added:</b>
                {record.comments}
              </p>
            ),
            rowExpandable: (record) => record.comments,
          }}
          columns={moCols}
          dataSource={localMakeOrders}
        />
      </div>

      {/* Edit Make Order */}
      <Modal
        title="Edit Make Order"
        visible={showEditModal}
        width={1200}
        style={{ top: 10 }}
        destroyOnClose={true}
        footer={null}
        onCancel={() => {
          setShowEditModal(false);
          setSelectedOrder({});
        }}
      >
        <MoCreateEditModal
          onOk={async (_finalObj) => {
            await createOrUpdateOrder(_finalObj);
            setShowEditModal(false);
            await getMakeOrders();
          }}
          onDelete={async (_moId) => {
            await deleteMakeOrder(_moId);
            setShowEditModal(false);
            await getMakeOrders();
          }}
          onCancel={() => {
            setShowEditModal(false);
            setSelectedOrder({});
          }}
          changeTab={changeTab}
          taskList={taskList}
          skuList={skuList}
          mo={selectedOrder}
        />
      </Modal>

      {/* Create Make Order */}
      <Modal
        title="Create a Make Order"
        visible={showCreateModal}
        footer={null}
        onCancel={() => {
          setShowCreateModal(false);
          setSelectedOrder({});
        }}
        okText="Create Make Order"
        okType="primary"
        width={1200}
        style={{ top: 10 }}
        destroyOnClose={true}
      >
        {showCreateModal && (
          <MoCreateEditModal
            changeTab={changeTab}
            locId={locationId}
            onOk={async (_finalObj) => {
              await createOrUpdateOrder(_finalObj);
              await getMakeOrders();
            }}
            onCancel={() => {
              setShowCreateModal(false);
              setSelectedOrder({});
            }}
            createMode={true}
            taskList={taskList}
            skuList={skuList}
            orderData={selectedOrder}
          />
        )}
      </Modal>

      {/* Fill Make Order */}
      <Modal
        title="Fill Order"
        visible={showFillModal}
        footer={null}
        onCancel={() => {
          setShowFillModal(false);
          setSelectedOrder({});
        }}
        width={1200}
        destroyOnClose={true}
      >
        <FillModalContent
          sku={skuList?.find((el) => el?.sku_id === selectedOrder?.sku_id)}
          onOk={async (_finalObj) => {
            await fillOrder(_finalObj);
            setShowFillModal(false);
            await getMakeOrders();
          }}
          onCancel={() => {
            setShowFillModal(false);
            setSelectedOrder({});
          }}
          orderData={selectedOrder}
        />
      </Modal>
    </div>
  );
};

const mapState = (state) => {
  return {
    locationId: state.data.selectedLocation?.id,
    locationName: state.data.selectedLocation?.name,
  };
};

export default connect(mapState, null)(MakeOrder);
