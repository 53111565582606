import { Modal, Input, Row, Col, Select, Divider } from "antd";
import { useState } from "react";

const TaskModal = ({ visible, onOk, onCancel }) => {
  const [taskName, setTaskName] = useState("");
  const [fallbackTaskName, setFallbackTaskName] = useState("");

  const [resource, setResource] = useState("");
  const [costPerHour, setCostPerHour] = useState(0);
  const [costPerMin, setCostPerMin] = useState(0);
  const [minPerKg, setMinPerKg] = useState(0);
  const [costPerKg, setCostPerKg] = useState(0);

  const changeHandler = (type, value) => {
    switch (type) {
      case "costPerHour":
        setCostPerHour(value);
        setCostPerMin(value / 60);

        setCostPerKg((value / 60) * minPerKg);
        break;
      case "costPerMin":
        setCostPerMin(value);
        setCostPerHour(value * 60);

        setCostPerKg(value * minPerKg);
        break;
      case "minPerKg":
        setMinPerKg(value);

        setCostPerKg(value * costPerMin);
        break;
      case "costPerKg":
        setCostPerKg(value);

        setMinPerKg(value / costPerMin);
        break;
      default:
        break;
    }
  };

  const okHandler = () => {
    const _temp = {
      name: taskName.toLowerCase() !== "other" ? taskName : fallbackTaskName,
      resource,
      cost_per_hour: costPerHour,
      cost_per_min: costPerMin,
      min_per_unit: minPerKg,
      cost_per_unit: costPerKg,
    };

    onOk(_temp);
  };

  const TASKS_LIST = [
    "Cleaning",
    "Sorting",
    "Filling the product",
    "Sealing",
    "Label Cutting",
    "Label Application",
    "Grinding",
    "Label Printing",
    "Box Packaging",
    "Other",
  ];

  return (
    <Modal
      destroyOnClose={true}
      title="Create a task"
      visible={visible}
      onOk={okHandler}
      onCancel={onCancel}
      okText="Create Task"
      okType="primary"
      width={1200}
      style={{ top: 10 }}
    >
      <div>
        <Row gutter={0}>
          <Col span={6} offset={0}>
          <div style={{display: "flex"}}><p>Select Task</p><p style={{color: "crimson" , fontSize: "22px", margin: "-5px 0 0 5px" , }}>*</p></div> 
            <Select
              showSearch
              style={{ width: "100%" }}
              value={taskName}
              onChange={(_tn) => setTaskName(_tn)}
            >
              {TASKS_LIST.map((t) => (
                <Select.Option key={t} value={t}>{t}</Select.Option>
              ))}
            </Select>
          </Col>
          {taskName.toLowerCase() === "other" && (
            <Col span={6} offset={1}>
              <div style={{display: "flex"}}><p>Enter the name of the task</p><p style={{color: "crimson" , fontSize: "22px", margin: "-5px 0 0 5px" , }}>*</p></div> 
              <Input
                value={fallbackTaskName}
                onChange={(e) => setFallbackTaskName(e.target.value)}
              />
            </Col>
          )}
          <Col span={6} offset={3}>
          <div style={{display: "flex"}}><p>Resource (Human / Machine)</p><p style={{color: "crimson" , fontSize: "22px", margin: "-5px 0 0 5px" , }}>*</p></div> 
            <Input
              value={resource}
              onChange={(e) => setResource(e.target.value)}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={6}>
            <div>
            <div style={{display: "flex"}}><p>Cost per hour (Resource-oriented)</p><p style={{color: "crimson" , fontSize: "22px", margin: "-5px 0 0 5px" , }}>*</p></div> 
              <Input
                prefix="₹"
                onChange={(e) => changeHandler("costPerHour", e.target.value)}
                value={costPerHour}
              />
            </div>

            <Divider>OR</Divider>

            <div>
            <div style={{display: "flex"}}><p>Cost per Minute (Resource-oriented)</p><p style={{color: "crimson" , fontSize: "22px", margin: "-5px 0 0 5px" , }}>*</p></div> 
              <Input
                prefix="₹"
                onChange={(e) => changeHandler("costPerMin", e.target.value)}
                value={costPerMin}
              />
            </div>
          </Col>

          <Col span={6} offset={3}>
            <div>
            <div style={{display: "flex"}}><p>Minutes per Kg (Resource-oriented)</p><p style={{color: "crimson" , fontSize: "22px", margin: "-5px 0 0 5px" , }}>*</p></div> 
              <Input
                suffix="mins"
                onChange={(e) => changeHandler("minPerKg", e.target.value)}
                value={minPerKg}
              />
            </div>
            <Divider>OR</Divider>

            <div>
            <div style={{display: "flex"}}><p>Cost per Kg (Resource-oriented)</p><p style={{color: "crimson" , fontSize: "22px", margin: "-5px 0 0 5px" , }}>*</p></div> 
              <Input
                prefix="₹"
                onChange={(e) => changeHandler("costPerKg", e.target.value)}
                value={costPerKg}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default TaskModal;
