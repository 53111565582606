import { SWITCH_THEME } from "../actions/actionTypes";

/**
 * UI Reducers deal with changing the state's keys that are kept for the UI.
 * @example `isDarkTheme`
 * @memberof Reducers
 */

/**
 * Modifies the `ui` state
* @param {Object} state - The current state object (before updation)
 * @param {Object} action - The action object as dispatched
 * @returns New state, which gets assigned to the `ui` key
 */
const uiReducer = (
  state = {
    isDarkTheme: ["true", true].includes(localStorage.getItem("isDarkTheme"))
      ? true
      : false
  },
  action
) => {
  switch (action.type) {
    case SWITCH_THEME:
      localStorage.setItem("isDarkTheme", !state.isDarkTheme);
      window.location.reload()
      // reload the window, because without it the previous CSS loaded files are not removed when the new ones are loaded.
      return {
        ...state,
        isDarkTheme: !state.isDarkTheme
      };
    default:
      return state;
  }
};

export default uiReducer;
