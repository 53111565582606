import React, { useEffect } from "react";
import { Tabs } from "antd";
import { TeamOutlined, SmileOutlined, CarOutlined } from "@ant-design/icons";
import SupplierSummary from "./suppliers/partners.suppliers.main";
import CustomerSummary from "./customers/partners.customers.main";
import TransporterMain from "./transporters/partners.transporters.main";
const { TabPane } = Tabs;

const PartnersScreen = () => {
  useEffect(() => {
    document.title = "Partners - Invento";
  }, []);

  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <span>
            <TeamOutlined />
            Suppliers
          </span>
        }
        key="1"
      >
        <SupplierSummary />
      </TabPane>
      <TabPane
        tab={
          <span>
            <SmileOutlined />
            Customers
          </span>
        }
        key="2"
      >
        <CustomerSummary />
      </TabPane>
      <TabPane
        tab={
          <span>
            <CarOutlined />
            Transporter
          </span>
        }
        key="3"
      >
        <TransporterMain />
      </TabPane>
    </Tabs>
  );
};

export default PartnersScreen;
