// Data Actions
export const FETCH_INITIATE = "FETCH_INITIATE"
export const FETCH_SUCCESS = "FETCH_SUCCESS"
export const FETCH_ERROR = "FETCH_ERROR"
export const CLEAR_ERROR = "CLEAR_ERROR"

export const GET_PURCHASE_ORDERS_LIST = "GET_PURCHASE_ORDERS_LIST"
export const GET_SALES_ORDERS_LIST = "GET_SALES_ORDERS_LIST"
export const GET_SUPPLIER_LIST = "GET_SUPPLIER_LIST"
export const GET_TRANSPORTER_LIST = "GET_TRANSPORTER_LIST"
export const GET_MATERIAL_LIST = "GET_MATERIAL_LIST"
export const GET_SKU_LIST = "GET_SKU_LIST"
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST"

export const GET_LOCATIONS_LIST = "GET_LOCATIONS_LIST"

export const GET_TAX_LIST = "GET_TAX_LIST"


export const GET_GROUPS_LIST = "GET_GROUPS_LIST"

export const GET_SUBGROUPS_LIST = "GET_SUBGROUPS_LIST"

export const GET_SUPPLIER_TYPE = "GET_SUPPLIER_TYPE"

export const SET_LOCATION = "SET_LOCATION"

export const GET_PAYMENT_TERMS = "GET_PAYMENT_TERMS"

export const GET_CUSTOMER_TYPE = "GET_CUSTOMER_TYPE"

export const GET_ITEM_PRICE_LIST = "GET_ITEM_PRICE_LIST"




// Auth Actions
export const SET_USER = "SET USER"
export const REMOVE_USER = "REMOVE_USER"


// UI Actions
export const SWITCH_THEME = "SWITCH_THEME"