import React, { useState, useEffect } from "react";
import { Button, Table, message } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { getList } from "../../../actions/dataActions";
import { connect } from "react-redux";
import SubGroupsModal from "./SubGroupsModal";
import DeleteConfirm from "./DeleteConfirm";
import axios from "axios";

const SubGroups = ({ getGroups, getSubGroups, list, MAINGROUPS }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    getGroups();
    getSubGroups();
  }, [getGroups, getSubGroups]);

  const deleteSubGroupHandler = async (id) => {
    try {
      await axios.delete("/api/subgroup/" + id);
      getSubGroups();
    } catch (err) {
      message.error("Error Deleting Groups");
    }
  };

  const columns = [
    {
      title: "Subgroups",
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <>
      <Button
        style={{ marginBottom: "1rem" }}
        onClick={showModal}
        type="primary"
      >
        <PlusOutlined /> Add SubGroups
      </Button>
      <Table dataSource={list} columns={columns} />
      <SubGroupsModal
        getSubGroups={getSubGroups}
        MAINGROUPS={MAINGROUPS}
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

const mapState = (state) => ({
  MAINGROUPS: state.data.grouplist,
  list: state.data.subgrouplist,
});

const mapDispatch = {
  getGroups: () => getList("groups"),
  getSubGroups: () => getList("subgroups"),
};

export default connect(mapState, mapDispatch)(SubGroups);
