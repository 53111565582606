import axios from "axios";
import { Button, Select, Table, Tooltip } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import TaxModal from "./TaxModal";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { getList } from "../../../actions/dataActions";
import { connect } from "react-redux";

const Tax = ({ getTaxes, taxes }) => {
  const NAMES = ["All", "HSN", "SAC"];
  const TYPES = ["All", "GST", "IGST", "CGST", "UTGST", "SGST"];

  const [taxList, setTaxList] = useState();
  const [filteredTaxList, setFilteredTaxList] = useState();
  const [typeOfModal, setTypeOfModal] = useState();
  const [selectedTax, setSelectedTax] = useState();
  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = (type, selectedTax) => {
    setTypeOfModal(type);
    setModalVisible(true);
    setSelectedTax(selectedTax);
  };

  useEffect(() => {
    getTaxes();
    //eslint-disable-next-line
  }, []);

  const taxCols = [
    {
      title: "Tax Code",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
      width: 150,
      ...useTableSearch("code"),
    },

    {
      title: "Name",
      width: 250,
      dataIndex: "name",
      ...useTableSearch("name"),
    },

    {
      title: "Type",
      dataIndex: "type",
      defaultSortOrder: "descend",
      ...useTableSearch("type"),
    },
    {
      title: "Value",
      dataIndex: "value",
      sorter: (a, b) => a.value - b.value,
      ...useTableSearch("type"),
    },
    {
      title: "Actions",
      width: 150,
      render: (value, record) => {
        return (
          <>
            <div className="flex">
              <Tooltip title="Edit">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    showModal("edit", record);
                  }}
                  danger
                />
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Button onClick={() => showModal("add")} type="primary">
        <PlusOutlined /> Add Tax
      </Button>

      <Table
        style={{
          maxWidth: "800px",
        }}
        columns={taxCols}
        dataSource={taxes?.list}
      />

      {isModalVisible && (
        <TaxModal
          setSelectedTax={setSelectedTax}
          setModalVisible={setModalVisible}
          setTypeOfModal={setTypeOfModal}
          selectedTax={selectedTax}
          typeOfModal={typeOfModal}
          isModalVisible={isModalVisible}
        />
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    taxes: state.data.taxes || {
      list: [],
      types: [],
      names: [],
    },
  };
};

const mapDispatch = {
  getTaxes: () => getList("taxes"),
};

export default connect(mapState, mapDispatch)(Tax);
