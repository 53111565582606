import React from "react";
import { Text, Page, View, Image, Document } from "@react-pdf/renderer";
import { styles, comb } from "../../styles";
import fallbackLogo from "../../../../assets/images/logo.png";
import { Space } from "antd";
import moment from "moment";
import { PAYMENT_TERMS } from "../../../../assets/constants";
import commaSeparatedRupees from "../../../../common/functions/commaSeparatedRupees";
import numberToWords from "../../../../common/functions/numberToWords";
import getAddress from "../../../../common/functions/getAddress";
import { roundValue } from "../../../../common/functions/roundValue";

const SalesOrderInvoice = ({ data }) => {
  const tablesReturner = () => {
    const len = data.items.length;
    const ITEMS_PER_PAGE = 8;
    const MAX_ITEMS_IN_OTHER_PAGES = 15;

    if (len <= ITEMS_PER_PAGE) return <Table items={data.items} />;
    else {
      let _insertedInPage = 0;

      let _firstPage = (
        <>
          <Table items={data.items.slice(0, 8)} />
          <View break />
        </>
      );
      let _combinedArr = [_firstPage];
      for (let i = 8; i < len; i = i + MAX_ITEMS_IN_OTHER_PAGES) {
        let _items = data.items.slice(i, i + MAX_ITEMS_IN_OTHER_PAGES);
        _insertedInPage += _items.length;
        _combinedArr.push(
          <>
            <Table items={_items} />
            {_insertedInPage >= MAX_ITEMS_IN_OTHER_PAGES && <View break />}
          </>
        );
        if (_insertedInPage >= MAX_ITEMS_IN_OTHER_PAGES) _insertedInPage = 0;
      }
      return _combinedArr;
    }
  };

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <Text
            style={[
              styles.upperCase,
              styles.bold,
              {
                marginBottom: 10,
                fontSize: 20,
                textAlign: "center",
                color: "darkgreen",
              },
            ]}
          >
            Sales Order
          </Text>
            
          <InvoiceHeader soDetails={data.so_details} headerInfo={data.header} />

          <SourceDestination
            consignee={data.consignee}
            customer={data.customer}
          />

          <TermsOfInvoice
            soDetails={data.so_details}
            transporter={data.transporter}
          />

          {/* <Table /> */}
          {tablesReturner()}

          <CommentsAndBreakUp items={data.items} soDetails={data.so_details} />

          <ClosingNotes />
        </Page>
      </Document>
    </>
  );
};

const InvoiceHeader = ({ soDetails, headerInfo }) => {
  const { id, created_date } = soDetails;
  const { GST, address, city, email, logo, name, pincode, state } = headerInfo;

  console.log(logo)

  return (
    <>
      <View style={[styles.flex, styles.justifyBetween, { marginBottom: 10 }]}>
        {/* Top Left */}
        <View
          style={[
            styles.flexCol,
            styles.fontZeroPointNineEm,
            { maxWidth: 300 },
          ]}
        >
          <Text style={[styles.bold, styles.upperCase, { fontSize: 12 }]}>
            {name}
          </Text>
          <Text>{address}</Text>
          <Text>
            {state}, {pincode}
          </Text>
          {/* GSTIN */}
          <Text style={{ paddingTop: 5 }}>
            <Text style={styles.bold}>GSTIN / UIN: </Text>
            <Text>{GST}</Text>
          </Text>
          {/* State Name */}
          <Text>
            <Text style={styles.bold}>State Name: </Text>
            <Text>{state}</Text>
          </Text>
          {/* Email */}
          <Text>
            <Text style={styles.bold}>Email: </Text>
            <Text style={{ color: "darkblue" }}>{email}</Text>
          </Text>
        </View>

        {/* Top Right */}
        <View style={styles.flexCol}>
          <Image
        
            source={headerInfo?.logo || fallbackLogo}
            style={{ width: 60, height: 40, margin: "auto", marginBottom: 10 }}
          />
          <Text>
            <Text style={styles.bold}>Creation Date:</Text>
            <Text>{moment(created_date).format("Do MMMM YYYY")}</Text>
          </Text>
          <Text>
            <Text style={styles.bold}>Print Date:</Text>
            <Text>{moment().format("Do MMMM YYYY")}</Text>
          </Text>
          <Text>
            <Text style={styles.bold}>SO #: </Text>
            <Text>{id}</Text>
          </Text>
        </View>
      </View>
      <View
        style={{
          height: 1,
          backgroundColor: "grey",
          width: "100%",
          marginBottom: 20,
        }}
      />
    </>
  );
};

const SourceDestination = ({
  consignee,
  customer = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    gstin: "",
    state: "",
    email: "",
    contact: "",
  },
}) => {
  const {
    name,
    contact,
    gstin,
    email,
    address1,
    address2,
    city,
    state,
    pincode,
    country,
  } = consignee || "";
  return (
    <View style={[styles.flex, { marginBottom: 10 }]}>
      <View style={[comb.sourceDestBox, { marginRight: 10 }]}>
        <Text style={comb.fromToText}>Consignee (Ship To)</Text>
        <View style={[styles.paddingFive, styles.flexCol]}>
          <View>
            <Text style={[styles.bold, styles.upperCase]}>{name}</Text>
          </View>
          {/* first address */}
          <View>
            <Text>{getAddress({ address1, address2, city, pincode })}</Text>
          </View>
          {/* GSTIN/UIN */}
          <View>
            <Text>
              <Text style={styles.bold}>GSTIN/UIN </Text>: {gstin}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>State Name</Text>: {state} ({country})
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>email</Text>: {email}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>Phone</Text>: {contact}
            </Text>
          </View>
        </View>
      </View>

      <View style={[comb.sourceDestBox]}>
        <Text style={comb.fromToText}>Buyer (Bill To)</Text>
        <View style={[styles.paddingFive, styles.flexCol]}>
          <View>
            <Text style={[styles.bold, styles.upperCase]}>{customer.name}</Text>
          </View>
          {/* first address */}
          <View>
            <Text>{getAddress(customer)}</Text>
          </View>
          {/* GSTIN/UIN */}
          <View>
            <Text>
              <Text style={styles.bold}>GSTIN/UIN </Text>: {customer.gstin}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>State Name</Text>: {customer.state} (
              {customer.country})
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>email</Text>: {customer.email}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>Phone</Text>: {customer.contact}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const TermsOfInvoice = ({ due_date, transporter = { name: "" }, ...rest }) => {
  const { soDetails } = rest;
  const { payment_terms } = soDetails;

  return (
    <View style={[styles.flex, { marginBottom: 10 }]}>
      <FromToText heading="Payment Terms" mainBody={`${payment_terms}`} />

      <FromToText
        heading="Order Status"
        mainBody={`${soDetails?.order_status || ""}`}
      />

      <FromToText
        heading="Delivery Terms"
        mainBody={soDetails.delivery_terms}
      />

      <FromToText
        heading="Shipping Method"
        mainBody={`Will be shipped by ${transporter.name}`}
      />
      <FromToText
        heading="Required by Date"
        mainBody={moment(due_date).format("Do MMMM YYYY")}
      />
    </View>
  );
};

const FromToText = ({ heading, mainBody, children }) => {
  return (
    <View style={[comb.sourceDestBox, { marginRight: 3 }]}>
      <Text style={[comb.fromToText, comb.termsText]}>{heading}</Text>

      <View style={styles.paddingFive}>
        <Text>{mainBody}</Text>
        {children}
      </View>
    </View>
  );
};

const Table = ({ items }) => {
  return (
    <View style={[styles.flexTable]}>
      <TableHeader />
      <TableData items={items} />
    </View>
  );
};

const TableHeader = ({ items }) => {
  return (
    <View style={[styles.flexTableHeader, styles.flex]}>
      <View style={[styles.flexTableTd, styles.columnSNo]}>
        <Text>S.No</Text>
      </View>

      <View style={[styles.flexTableTd, styles.columnDescription]}>
        <Text>Description</Text>
      </View>

      {/* HSN */}
      <View style={[styles.flexTableTd, styles.columnHsn]}>
        <Text>HSN / SAC</Text>
      </View>

      {/* Qty */}
      <View style={[styles.flexTableTd, styles.columnQty]}>
        <Text style={styles.textRight}>Qty</Text>
      </View>

      {/* Rate */}
      <View style={[styles.flexTableTd, styles.columnRate]}>
        <Text style={styles.textRight}>Rate (INR)</Text>
      </View>

      {/* GST breakup */}
      <View style={[styles.flexTableTd, styles.columnGst, styles.flexCol]}>
        <View>
          <Text style={styles.textCenter}>GST</Text>
        </View>
        <View
          style={[
            styles.flex,
            styles.justifyBetween,
            { borderTopWidth: 1, borderTopColor: "white" },
          ]}
        >
          <Text style={{ width: "50%" }}>%</Text>
          <Text style={{ width: "50%" }}>Value</Text>
        </View>
      </View>

      {/* <View style={[styles.flexTableTd, { width: 50, maxWidth: 50 }]}>
          <Text style={{ textAlign: "center" }}>Unit</Text>
        </View> */}

      {/* Discount */}
      <View
        style={[styles.flexTableTd, styles.columnDiscount, styles.textRight]}
      >
        <Text>Disc</Text>
        <Text>(%)</Text>
      </View>

      {/* Amount */}
      <View
        style={[styles.flexTableTd, styles.borderRightZero, styles.textRight]}
      >
        <Text>Amount</Text>
        <Text>(INR)</Text>
      </View>
    </View>
  );
};

const TableData = ({ items }) => {
  return (
    <>
      {items.map(
        (
          {
            item_type,
            name,
            so_id,
            product_name,
            id,
            quantity,
            uom,
            rate,
            tax,
            tax_breakup,
            amount,
            discount,
            sNo,
            tax_code,
          },
          i
        ) => {
          return (
            <View
              key={id}
              style={{
                flexDirection: "row",
                borderBottomWidth: 1,
                borderBottomColor: "lightgrey",
                backgroundColor: i % 2 ? "white" : "whitesmoke",
              }}
            >
              {/* S.No */}
              <View style={[styles.flexTableTd, styles.columnSNo]}>
                <Text>{sNo}</Text>
              </View>

              {/* Description */}
              <View
                style={[
                  styles.flexTableTd,
                  styles.flexCol,
                  styles.columnDescription,
                ]}
              >
                <Text style={styles.bold}>{name || product_name}</Text>
              </View>

              {/* HSN / SAC */}
              <View style={[styles.flexTableTd, styles.columnHsn]}>
                <Text>
                  {so_id} {tax_code}
                </Text>
              </View>

              {/* Qty */}
              <View style={[styles.flexTableTd, styles.columnQty]}>
                <Text style={styles.textRight}>{quantity}</Text>
              </View>

              {/* Rate */}
              <View style={[styles.flexTableTd, styles.columnRate]}>
                <Text style={styles.textRight}>
                  {commaSeparatedRupees(rate)}
                </Text>
              </View>

              {/* GST Breakup */}
              <View style={[styles.flexTableTd, styles.columnGst]}>
                {Object.entries(tax_breakup).map(([key, value]) => {
                  return (
                    <View style={[styles.flex, styles.justifyBetween]}>
                      <Text>{`${key} @${value}%`}</Text>
                      <Text>{`${roundValue(
                        ((value || 0) / 100) * (rate * quantity)
                      )}`}</Text>
                    </View>
                  );
                })}
              </View>

              {/* Discount */}
              <View style={[styles.flexTableTd, styles.columnDiscount]}>
                <Text style={styles.textRight}>{discount}%</Text>
              </View>

              {/* Amount */}
              <View
                style={[
                  styles.flexTableTd,
                  styles.borderRightZero,
                  styles.textRight,
                ]}
              >
                <Text>{commaSeparatedRupees(amount)}</Text>
              </View>
            </View>
          );
        }
      )}
    </>
  );
};

const CommentsAndBreakUp = ({
  soDetails: { comments, total_amount, transport_cost },
  items = [],
}) => {
  let totalTaxableAmt = 0;
  let totalGST = 0;
  let netTotalAmt = 0;

  items.forEach(({ tax, quantity, rate, amount, uom, discount, ...rest }) => {
    let temp = quantity * rate;
    let discountedTotal = temp - (temp * (discount || 0)) / 100;
    totalTaxableAmt += discountedTotal;
    totalGST += (temp * tax) / 100;
  });

  netTotalAmt = totalTaxableAmt + totalGST + transport_cost + 0;

  return (
    <>
      <View style={[styles.flex, { justifyContent: "space-between" }]}>
        <View style={{ marginTop: 5, width: "60%" }}>
          <FromToText heading="Comments / Special Instructions">
            <View style={styles.flexCol}>
              <Text>- Please send two copies of your invoice.</Text>
              <Text>
                - Enter this order in accordance with the prices, terms,
                delivery method, and specifications listed above.
              </Text>
              <Text>
                - Please notify us immediately if you are unable to ship as
                specified.
              </Text>

              {/* {comments && ( */}
              <>
                <View
                  style={{
                    height: 1,
                    backgroundColor: "lightgrey",
                    marginBottom: 2,
                  }}
                />
                <Text>- {comments}</Text>
              </>
              {/* )} */}
            </View>
          </FromToText>
        </View>

        <View
          style={[styles.flexOne, styles.flexCol, { alignItems: "flex-end" }]}
        >
          <View
            style={{
              alignItems: "flex-end",
              width: "70%",
              // width: "25%",
            }}
          >
            <View style={[styles.flexCol, { width: "100%" }]}>
              {/* Subtax, tax, etc. */}
              <View style={[styles.flex]}>
                <Text style={comb.breakUp.left}>Total Taxable Amt</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(totalTaxableAmt)}
                  </Text>
                </View>
              </View>

              <View style={styles.flex}>
                <Text style={comb.breakUp.left}>GST</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(totalGST)}
                  </Text>
                </View>
              </View>

              <View style={styles.flex}>
                <Text style={comb.breakUp.left}>Shipping</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(transport_cost)}
                  </Text>
                </View>
              </View>

              <View style={styles.flex}>
                <Text style={comb.breakUp.left}>Other</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {" "}
                    {commaSeparatedRupees(0)}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.flex,
                  styles.bold,
                  { marginTop: 1, borderTopWidth: 1 },
                ]}
              >
                <Text style={comb.breakUp.left}>Total</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(netTotalAmt)}
                  </Text>
                </View>
              </View>

              <View
                style={[
                  styles.flex,
                  styles.bold,
                  { marginTop: 1, borderTopWidth: 1 },
                ]}
              >
                <Text style={comb.breakUp.left}>Total (rounded)</Text>
                <View style={comb.breakUp.right}>
                  <Text style={styles.textRight}>
                    {commaSeparatedRupees(Math.round(netTotalAmt))}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 3, minWidth: "50%" }}>
            <Text
              style={{ backgroundColor: "black", color: "white", padding: 1 }}
            >
              IN WORDS:
            </Text>
            <Text style={styles.bold}>
              {numberToWords(Math.round(netTotalAmt))}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};
const ClosingNotes = () => {
  return (
    <>
      <View
        style={[
          styles.flex,
          styles.justifyCenter,
          {
            marginTop: 30,
            borderTopWidth: 1,
            borderTopColor: "lightgrey",
            paddingTop: 3,
          },
        ]}
      >
        <Text>This is a Computer Generated Invoice</Text>
      </View>
    </>
  );
};

export default SalesOrderInvoice;
