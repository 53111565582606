import { useEffect, useState } from "react";

import useTableSearch from "../../../common/hooks/useTableSearch";
import { Button, Select, Table, Tooltip } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";
import TermsModal from "./TermsModal";

const Terms = ({ getPaymentTerms, paymentTerms }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    getPaymentTerms();
    //eslint-disable-next-line
  }, []);

  const termsCols = [
    {
      title: "Name",
      width: 300,
      dataIndex: "name",
      ...useTableSearch("name"),
    },
    {
      title: "Type",
      width: 150,
      dataIndex: "type",
      defaultSortOrder: "descend",
      ...useTableSearch("type"),
    },

    {
      title: "Actions",
      width: 150,
      render: (value, record) => {
        return (
          <>
            <div className="flex">
              <Tooltip title="Edit">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  //   onClick={() => {
                  //     showModal("edit", record);
                  //   }}
                  danger
                />
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Button onClick={showModal} type="primary">
        <PlusOutlined /> Add Terms
      </Button>

      <Table
        style={{
          maxWidth: "800px",
        }}
        columns={termsCols}
        dataSource={paymentTerms}
      />

      {isModalVisible && (
        <TermsModal
          setModalVisible={setModalVisible}
          isModalVisible={isModalVisible}
        />
      )}
    </>
  );
};

const mapState = (state) => {
  return {
    paymentTerms: state.data.paymentTerms,
  };
};

const mapDispatch = {
  getPaymentTerms: () => getList("paymentTerms"),
};

export default connect(mapState, mapDispatch)(Terms);
