import axios from "axios";
import { useState } from "react";
import { Form, Input, Modal, Button, message } from "antd";

function GroupsModal({ isModalVisible, setModalVisible, getGroups }) {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [group, setGroup] = useState("");

  const cancelHandler = () => {
    setModalVisible(false);
  };

  const validateInput = (group) => {
    let error = "";
    if (group === "") {
      error = "Please Enter Group";
    }
    return error;
  };

  const okHandler = () => {
    setConfirmLoading(true);

    const errors = validateInput(group);

    const GroupObject = {
      name: group,
    };
    if (errors) {
      message.error(`${errors}`);
    }
    if (!errors) {
      createGroups(GroupObject);
      setModalVisible(false);
      setGroup("");
    }
    setConfirmLoading(false);
  };

  const createGroups = async (GroupObject) => {
    try {
      await axios.post("/api/group/", GroupObject);
      message.success("Group added Successfully");
      getGroups();
    } catch (err) {
      message.error("Error Updating Groups");
    }
  };

  return (
    <Modal
      title="Add Group"
      visible={isModalVisible}
      onOk={okHandler}
      onCancel={cancelHandler}
      confirmLoading={confirmLoading}
      footer={[
        <Button key="back" onClick={cancelHandler}>
          Cancel
        </Button>,
        <Button onClick={okHandler} type="primary" htmlType="submit">
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Group" required tooltip="e.g., 1122">
          <Input
            value={group}
            type="text"
            onChange={(e) => setGroup(e.target.value)}
            placeholder="Enter Group Here"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default GroupsModal;
