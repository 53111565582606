import Login from "../screens/Login";
import GoodsScreen from "../screens/goods";
import PartnersScreen from "../screens/partners";
import InventoryScreen from "../screens/inventory";
import SalesScreen from "../screens/sales";
import MakeScreen from "../screens/make";
import PurchaseScreen from "../screens/purchase";

import {
  Switch,
  Route,
  Redirect,
  useLocation,
  withRouter,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { connect } from "react-redux";
import Home from "../screens/home/home.main";
import Invoice from "../invoice";
import SettingsMain from "../screens/settings";


/**
 * Component that contains the Routing table of the app.
 * @returns {JSX.Element} - Fragment containing a Switch, which in turn checks and then redirect to, or renders the appropriate Component 
 */
const Routing = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/purchase" component={PurchaseScreen} />
        <PrivateRoute path="/sales" component={SalesScreen} />
        <PrivateRoute path="/make" component={MakeScreen} />
        <PrivateRoute path="/inventory" component={InventoryScreen} />
        <PrivateRoute path="/goods" component={GoodsScreen} />
        <PrivateRoute path="/partners" component={PartnersScreen} />

        <PrivateRoute path="/settings" component={SettingsMain} />


        {/* For Invoices */}
        <PrivateRoute path="/invoices/:type/:orderId" component={Invoice} />
      </Switch>
    </>
  );
};

const mapState = (state) => ({ isLoggedIn: state.auth.isLoggedIn });
export default connect(mapState)(withRouter(Routing));
