import axios from "axios";

export const SUPPLIER_TYPES = [
  { text: "Farmer", value: "Farmer" },
  { text: "FPO/FPC", value: "FPO/FPC" },
  { text: "Bulk Trader", value: "Bulk Trader" },
  { text: "Retail Store", value: "Retail Store" },
];

export const CUSTOMER_TYPES = [
  { text: "B2C", value: "B2C" },
  { text: "B2B", value: "B2B" },
  { text: "EXP", value: "EXP" },
];

export const TRANSPORTER_TYPES = [];

export const USER_TAGS = ["Regular Customer", "Export"];

export const STATES = [
  {
    text: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  {
    text: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },
  {
    text: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    text: "Assam",
    value: "Assam",
  },
  {
    text: "Bihar",
    value: "Bihar",
  },
  {
    text: "Chandigarh",
    value: "Chandigarh",
  },
  {
    text: "Chhattisgarh",
    value: "Chhattisgarh",
  },
  {
    text: "Dadra and Nagar Haveli",
    value: "Dadra and Nagar Haveli",
  },
  {
    text: "Daman and Diu",
    value: "Daman and Diu",
  },
  {
    text: "Delhi",
    value: "Delhi",
  },
  {
    text: "Goa",
    value: "Goa",
  },
  {
    text: "Gujarat",
    value: "Gujarat",
  },
  {
    text: "Haryana",
    value: "Haryana",
  },
  {
    text: "Himachal Pradesh",
    value: "Himachal Pradesh",
  },
  {
    text: "Jammu and Kashmir",
    value: "Jammu and Kashmir",
  },
  {
    text: "Jharkhand",
    value: "Jharkhand",
  },
  {
    text: "Karnataka",
    value: "Karnataka",
  },
  {
    text: "Kerala",
    value: "Kerala",
  },
  {
    text: "Ladakh",
    value: "Ladakh",
  },
  {
    text: "Lakshadweep",
    value: "Lakshadweep",
  },
  {
    text: "Madhya Pradesh",
    value: "Madhya Pradesh",
  },
  {
    text: "Maharashtra",
    value: "Maharashtra",
  },
  {
    text: "Manipur",
    value: "Manipur",
  },
  {
    text: "Meghalaya",
    value: "Meghalaya",
  },
  {
    text: "Mizoram",
    value: "Mizoram",
  },
  {
    text: "Nagaland",
    value: "Nagaland",
  },
  {
    text: "Odisha",
    value: "Odisha",
  },
  {
    text: "Puducherry",
    value: "Puducherry",
  },
  {
    text: "Punjab",
    value: "Punjab",
  },
  {
    text: "Rajasthan",
    value: "Rajasthan",
  },
  {
    text: "Sikkim",
    value: "Sikkim",
  },
  {
    text: "Tamil Nadu",
    value: "Tamil Nadu",
  },
  {
    text: "Telangana",
    value: "Telangana",
  },
  {
    text: "Tripura",
    value: "Tripura",
  },
  {
    text: "Uttar Pradesh",
    value: "Uttar Pradesh",
  },
  {
    text: "Uttarakhand",
    value: "Uttarakhand",
  },
  {
    text: "West Bengal",
    value: "West Bengal",
  },
];
