import React, { useEffect } from "react";
import { Tabs } from "antd";
import { CodeSandboxOutlined, BarcodeOutlined } from "@ant-design/icons";
import MatInvSummary from "./materials/inventory.materials.main";
import SKUInvSummary from "./skus/inventory.skus.main";

const { TabPane } = Tabs;

const InventoryScreen = () => {
  useEffect(() => {
    document.title = "Inventory - Invento";
  }, []);

  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <span>
            <CodeSandboxOutlined />
            Material Inventory
          </span>
        }
        key="1"
      >
        <MatInvSummary />
      </TabPane>
      <TabPane
        tab={
          <span>
            <BarcodeOutlined />
            SKU Inventory
          </span>
        }
        key="2"
      >
        <SKUInvSummary />
      </TabPane>
    </Tabs>
  );
};

export default InventoryScreen;
