import {
  AutoComplete,
  Form,
  Input,
  Modal,
  Button,
  message,
  Select,
  Row,
  Col,
} from "antd";
import axios from "axios";
import { useState } from "react";

const TermsModal = ({ isModalVisible, setModalVisible }) => {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const cancelHandler = () => {
    setModalVisible(false);
  };

  const okHandler = async () => {
    let finalObject = {
      name: name,
      type: type,
    };

    try {
      const res = await axios.post("/api/term", finalObject);
      console.log(res, "response");
      if (res.status === 200) {
        message.success("Added Terms");
        setType("");
        setName("");
        setModalVisible(false);
      }

    } catch (error) {
      message.error("Error Updating terms, Please try again later");
    }
  };
  return (
    <Modal
      title="Add Terms"
      visible={isModalVisible}
      onOk={okHandler}
      onCancel={cancelHandler}
    >
      <Form layout="vertical">
        <Row>
          <Col offset={1}>
            <Form.Item label="Type" required tooltip="e.g., general">
              <Input
                required
                placeholder="Type"
                onChange={(e) => setType(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col offset={1}>
          <Form.Item label="Name" required tooltip="e.g., PIA">
            <Input
              required
              placeholder="Name of Terms"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default TermsModal;
