import { Suspense, lazy } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types"
const LightTheme = lazy(() => import("./lightTheme"));
const DarkTheme = lazy(() => import("./darkTheme"));


/**
 * Component that renders Dark Theme or Light Theme, depending on the value stored in the Redux store
 * @param {{children: JSX.Element}} children - Any children desired
 * @param {{boolean}} darkMode - Whether the mode chosen by the user is dark (true) or light (false)
 */
const ThemeSelector = ({ children, darkMode }) => {
  return (
    <>
      <Suspense fallback={<></>}>
        {darkMode ? <DarkTheme /> : <LightTheme />}
      </Suspense>
      {children}
    </>
  );
};

ThemeSelector.propTypes = {
  darkMode: false
}

const mapState = state => ({
  darkMode: state.ui.isDarkTheme
});

export default connect(mapState)(ThemeSelector);
