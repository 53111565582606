import { Input, Button, Divider, notification } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useState } from "react";
import axios from "axios";
import { removeUser } from "../../../actions/authActions";
import { connect } from "react-redux";

const UserSettings = ({ email, username, removeUser }) => {
  const [userDetails, setUserDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [emailId, setEmailId] = useState(email);
  const [Name, setName] = useState(username);
  const [errorMessage, setErrorMessage] = useState("");

  const openNotificationWithIcon = (type) => {
    switch (type) {
      case "success":
        return notification[type]({
          message: "Success",
          description:
            "The User profile is updated successfully, You will be logged out, Please login again to see changes",
        });
      case "error":
        return notification[type]({
          message: "Unsuccessful",
          description: "The update was unsuccessful, Please try again later.",
        });
      default:
        return;
    }
  };

  const onChangeHandler = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const changePassword = async () => {
    const passwordMatch =
      userDetails.confirmNewPassword !== "" &&
      userDetails.confirmNewPassword === userDetails.newPassword;
    const isPasswordValid = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(
      userDetails.newPassword
    );
    if (!passwordMatch) {
      setErrorMessage("Password Do not Match");
    } else if (!isPasswordValid) {
      setErrorMessage(
        "Make sure password is atleast 8 characters long and have a uppercase letter and a number"
      );
    } else {
      await changePasswordHandler();
    }
  };

  const changePasswordHandler = async () => {
    const { oldPassword, newPassword, confirmNewPassword } = userDetails;
    if (![oldPassword, newPassword, confirmNewPassword].includes("")) {
      try {
        const response = await axios.post(
          "https://staging-sso.agrotrust.io/sso/api/user/resetPassword",
          {
            password: oldPassword,
            newPass: newPassword,
          }
        );

        if (response.status === 200) {
          openNotificationWithIcon("success");
          setTimeout(() => removeUser(), 5000);
        }
      } catch (error) {
        openNotificationWithIcon("error");
      }
    }
  };

  const changeUsernameOrEmail = async () => {
    const payload = {};
    if (email === emailId && username === Name) {
      return;
    }
    if (email !== emailId) payload["email"] = emailId;
    if (username !== Name) payload["name"] = Name;

    try {
      const response = await axios.patch(
        "https://staging-sso.agrotrust.io/sso/api/user",
        payload
      );
      if (response.status === 200) {
        openNotificationWithIcon("success");
        setTimeout(() => removeUser(), 5000);
      }
    } catch (error) {
      openNotificationWithIcon("error");
    }
  };

  return (
    <div>
      <div>
        <h1 style={{ fontSize: "1rem", fontWeight: "semibold" }}>
          Change Password
        </h1>
        <div style={{ padding: "0.5rem" }}>
          <p style={{ margin: "0" }}>Old Password</p>
          <Input.Password
            style={{ width: "30rem" }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            name="oldPassword"
            value={userDetails.oldPassword}
            onChange={onChangeHandler}
          />
        </div>

        <div style={{ padding: "0.5rem" }}>
          <p style={{ margin: "0" }}>New Password</p>
          <Input.Password
            style={{ width: "30rem" }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            name="newPassword"
            value={userDetails.newPassword}
            onChange={onChangeHandler}
          />
        </div>
        <div style={{ padding: "0.5rem" }}>
          <p style={{ margin: "0" }}>Confirm New Password</p>
          <Input.Password
            style={{ width: "30rem" }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            name="confirmNewPassword"
            value={userDetails.confirmNewPassword}
            onChange={onChangeHandler}
          />
        </div>

        <div style={{ padding: "1rem", color: "red" }}>
          <small>{errorMessage}</small>
        </div>

        <div style={{ margin: "1.5rem" }}>
          <Button
            type="primary"
            style={{ width: "6rem" }}
            onClick={changePassword}
          >
            Update
          </Button>
        </div>
      </div>

      <Divider />

      <div>
        <h1>User Settings</h1>
        <div>
          <div style={{ padding: "0.5rem" }}>
            <p style={{ margin: "0" }}>User Name</p>
            <Input
              style={{ width: "30rem" }}
              defaultValue={username}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div style={{ padding: "0.5rem" }}>
            <p style={{ margin: "0" }}>Email</p>
            <Input
              style={{ width: "30rem" }}
              defaultValue={email}
              onChange={(e) => setEmailId(e.target.value)}
            />
          </div>
        </div>
        <div style={{ margin: "1.5rem" }}>
          <Button
            type="primary"
            style={{ width: "6rem" }}
            onClick={changeUsernameOrEmail}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.auth?.user?.email,
    username: state.auth?.user.name,
  };
};

const mapDispatchProps = {
  removeUser,
};

export default connect(mapStateToProps, mapDispatchProps)(UserSettings);
