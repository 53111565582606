import { useState } from "react";
import { Card, Statistic, Row, Col, Button, Table } from "antd";
import paginationConfig from "./paginationConfig.json";

const SkuAlert = ({ _data, history }) => {
  const { count, data } = _data || 0;

  const _cols = [
    { title: "SKU", dataIndex: "sku" },
    {
      title: "Units in Stock",
      dataIndex: "instock",
    },
  ];

  return (
    <>
      <Card size="small" className="flex-1" style={{ marginRight: 10 }}>
        <Row gutter={16} style={{ paddingTop: 10 }}>
          <Col span={24} style={{ textAlign: "center", marginBottom: 10 }}>
            <Statistic title={"SKUs out of stock"} value={count} />
            <Button
              onClick={() => history.push("/inventory")}
              style={{ marginTop: 0 }}
              type="primary"
            >
              Show in inventory
            </Button>
          </Col>

          <Col span={24}>
            <Table
              // rowKey={record => record.sku_id}
              pagination={paginationConfig}
              columns={_cols}
              dataSource={data}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SkuAlert;
