import {
  FETCH_INITIATE,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_PURCHASE_ORDERS_LIST,
  GET_SUPPLIER_LIST,
  GET_MATERIAL_LIST,
  GET_CUSTOMER_LIST,
  GET_SALES_ORDERS_LIST,
  GET_SKU_LIST,
  GET_TRANSPORTER_LIST,
  CLEAR_ERROR,
  GET_LOCATIONS_LIST,
  SET_LOCATION,
  GET_TAX_LIST,
  GET_GROUPS_LIST,
  GET_SUBGROUPS_LIST,
  GET_SUPPLIER_TYPE,
  GET_PAYMENT_TERMS,
  GET_CUSTOMER_TYPE,
  GET_ITEM_PRICE_LIST,
} from "../actions/actionTypes";

/**
 * Data Reducers deal with changing the state's values that are kept for storing data obtained from HTTP requests' responses.<br/>
 * @memberof Reducers
 */

/**
 * Modifies the `data` state
 * @param {Object} state - The current state object (before updation)
 * @param {Object} action - The action object as dispatched
 * @returns New state, which gets assigned to the `data` key
 */
export const dataReducer = (
  state = {
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case GET_PURCHASE_ORDERS_LIST:
      return {
        ...state,
        purchaseOrders: action.payload.data,
        isLoading: false,
      };
    case GET_SUPPLIER_LIST:
      return {
        ...state,
        suppliers: action.payload.data,
        isLoading: false,
      };
    case GET_TRANSPORTER_LIST:
      return {
        ...state,
        transporters: action.payload.data,
        isLoading: false,
      };
    case GET_CUSTOMER_LIST:
      return {
        ...state,
        customers: action.payload.data,
        isLoading: false,
      };
    case GET_ITEM_PRICE_LIST:
      return {
        ...state,
        itemPrices: action.payload.data,
        isLoading: false,
      };
    case GET_SALES_ORDERS_LIST:
      return {
        ...state,
        salesOrders: action.payload.data,
        isLoading: false,
      };
    case GET_SKU_LIST:
      return {
        ...state,
        skus: action.payload.data,
        isLoading: false,
      };
    case GET_TAX_LIST:
      const taxList = action.payload.data;

      let distinctTaxNames = new Set();
      let distinctTaxTypes = new Set();

      taxList.forEach((el) => {
        distinctTaxNames.add(el.name);
        distinctTaxTypes.add(el.type);
      });

      distinctTaxNames = Array.from(distinctTaxNames);
      distinctTaxTypes = Array.from(distinctTaxTypes);

      return {
        ...state,
        taxes: {
          list: taxList,
          names: distinctTaxNames,
          types: distinctTaxTypes,
        },
      };
    case GET_GROUPS_LIST:
      const GroupList = action.payload.data;
      return {
        ...state,
        grouplist: GroupList,
        isLoading: false,
      };
    case GET_SUBGROUPS_LIST:
      const SubGroupList = action.payload.data;
      return {
        ...state,
        subgrouplist: SubGroupList,
        isLoading: false,
      };
    case GET_MATERIAL_LIST:
      return {
        ...state,
        materials: action.payload.data,
        isLoading: false,
      };
    case GET_LOCATIONS_LIST:
      return {
        ...state,
        locations: action.payload.data,
        selectedLocation: { ...action.payload.data[0] },
        isLoading: false,
      };
    case SET_LOCATION:
      const loc = state.locations?.find((el) => el.id === action.payload.data);
      return {
        ...state,
        selectedLocation: loc,
      };
    case GET_SUPPLIER_TYPE:
      return {
        ...state,
        supplierType: action.payload.data,
        isLoading: false,
      };
    case GET_CUSTOMER_TYPE:
      return {
        ...state,
        customerType: action.payload.data,
        isLoading: false,
      };
    case GET_PAYMENT_TERMS:
      return {
        ...state,
        paymentTerms: action.payload.data,
        isLoading: false,
      };

    // @ Axios-related states that indicate loading / complete / error
    case FETCH_INITIATE:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default dataReducer;
