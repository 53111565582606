import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "./assets/styles/general.css";
import axios from "axios";
import { loginViaToken } from "./actions/authActions";
import ThemeSelector from "./themes/themeSelector";


/**
 * File will set the baseURL for axios. When the baseUrl is set, there's no need to write the domain name when using axios to make a request.
 * This means after we've set `https://taru.agrotrust.io` as the baseUrl, we can simply write `axios.get('/someEndpoint')` instead of `axios.get('https://taru.agrotrust.io/someEndpoint')` in the code
 */

// axios.defaults.baseURL = "https://staging-invento.agrotrust.io";
axios.defaults.baseURL = process.env.REACT_APP_DOMAIN || (window.location.protocol + "//" + window.location.host);
// axios.defaults.baseURL = "http://localhost:7001";


/**
 * if jwtToken already exists, it means that the user is already logged in.
 */
if (localStorage.jwtToken) {
  store.dispatch(loginViaToken(localStorage.jwtToken));
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeSelector>
      <App />
    </ThemeSelector>
  </Provider>,
  document.getElementById("root")
);
