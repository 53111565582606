import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "antd";
import Navbar from "./layout/Navbar";
import { connect } from "react-redux";
import Routing from "./routing/Routing";
import { useState, useEffect } from "react";
import agroTrustLogo from "./assets/images/logo.png";

const { Header, Content, Footer } = Layout;

/**
 * The main App component
 * @param {Boolean} isLoggedIn - To indicate whether or not the user is logged in.
 * @param {Boolean} isDarkTheme - Whether or not the theme selected by the user is dark.
 * @returns {JSX.Element} - Router as Parent Component to wrap the Routing around it
 */
const App = ({ isLoggedIn, isDarkTheme }) => {
  const [showMain, setShowMain] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowMain(true);
    }, 1000);
  }, []);

  if (!showMain)
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: "100vh" }}
      >
        <img
          src={agroTrustLogo}
          style={{ maxWidth: "30vw" }}
          alt="AgroTrust logo"
        />
      </div>
    );

  return (
    <Router>
      <CustomLayout
        isLoggedIn={isLoggedIn}
        isDarkTheme={isDarkTheme}
      >
        <Routing />
      </CustomLayout>
    </Router>
  );
};


/**
 * Along with the Children, it will conditionally render Header depending on whether the user is logged in or not.
 * @param {{JSX.Element}} children - React Children of the Component
 * @param  {{boolean}} isLoggedIn - To indicate whether or not the user is logged in.
 * @param {{boolean}} isDarkTheme - Whether or not the theme selected by the user is dark.
 * @returns {JSX.Element} - Layout, with the header if the user has logged in, without header if the user hasn't logged in.
 */
const CustomLayout = ({ children, isLoggedIn, isDarkTheme }) => {
  const hideTheRest = window?.location?.pathname?.includes("invoices");
  if (hideTheRest) return children;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isLoggedIn && (
        <Header
          className="header"
          style={{ background: isDarkTheme ? "#141414" : "white" }}
        >
          <div className="logo" />
          <Navbar />
        </Header>
      )}

      <Content style={{ padding: "0px" }}>
        <Layout
          className="site-layout-background"
          style={{ padding: "10px 25px", background: "#0000" }}
        >
          <Content style={{ padding: "10px", minHeight: 280 }}>
            {children}
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        AgroTrust Invento ©2021 Created by Emertech Innovations
      </Footer>
    </Layout>
  );
};

const mapState = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isDarkTheme: state.ui.isDarkTheme,
});

export default connect(mapState)(App);
