import React, { useEffect } from "react";
import { Tabs } from "antd";
import { DiffOutlined, CarryOutOutlined } from "@ant-design/icons";
import SalesOrders from "./sales-orders/salesOrders.main";
import GoodsDeliveryNotes from "./goods-delivery-notes/goodsDeliveryNotes.main";
import Reports from "./reports/reports.main";
const { TabPane } = Tabs;

const SalesScreen = () => {

  useEffect(() => {
    document.title = "Sales - Invento";
  }, []);


  return (
    <Tabs destroyInactiveTabPane={true} defaultActiveKey="1">
      <TabPane
        tab={
          <span>
            <DiffOutlined />
            Sales Orders
          </span>
        }
        key="1"
      >
        {/* <SOSummary /> */}
        <SalesOrders />
      </TabPane>
      <TabPane
        tab={
          <span>
            <CarryOutOutlined />
            Goods Delivery Notes
          </span>
        }
        key="2"
      >
        <GoodsDeliveryNotes />
      </TabPane>

      <TabPane
        tab={
          <span>
            <CarryOutOutlined />
            Reports
          </span>
        }
        key="3"
      >
        <Reports/>
      </TabPane>
    </Tabs>
  );
};

export default SalesScreen;
