import { useEffect} from "react"
import { Tabs } from 'antd';
import { AppleOutlined, AimOutlined, GoldOutlined, AliwangwangOutlined, DollarOutlined, UserOutlined } from '@ant-design/icons';
import Locations from './locations';
import Tax from './tax';
import UserSettings from './users';
import GroupSettings from './groups';
import Terms from './terms'
import PriceMaster from "./priceMaster";


const { TabPane } = Tabs;


const SETTINGS_OPTIONS = [
    { name: "Locations", Component: <Locations />, Icon: <AimOutlined /> },
    { name: "Tax", Component: <Tax />, Icon: <DollarOutlined /> },
    { name: "Terms", Component: <Terms/> , Icon: <AliwangwangOutlined /> },
    { name: "Groups & Subgroups", Component: <GroupSettings/>, Icon: <GoldOutlined /> },
    { name: "User", Component: <UserSettings/>, Icon: <UserOutlined /> },
    {name: "Price Master", Component: <PriceMaster/>, Icon: <GoldOutlined/>}
]

const SettingsMain = () => {

    useEffect(()=>{
        document.title = "Settings - Invento"
    },[])

    return (
        <div>
            Settings Screen
            {/* <Locations /> */}
            <Tabs>
                {SETTINGS_OPTIONS.map(({ name, Icon, Component }, idx) => <TabPane tab={<span>{Icon || <AppleOutlined />} {name}</span>} key={idx}>{Component}</TabPane>)}
            </Tabs>
        </div>
    )
}





export default SettingsMain