import { Table, Button, Input, Space, message } from "antd";
import { useEffect, useState, useRef } from "react";
import { taskCols } from "./tasks.tableConstants";
import axios from "axios";
import TaskModal from "./tasks.taskModal";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { isEmpty } from "../../../common/functions/validateInputs";

const Tasks = () => {
  const [taskList, setTaskList] = useState([]);
  const [showTaskModal, setShowTaskModal] = useState(false);

  // Search

  const taskCols = [
    {
      title: "ID",
      dataIndex: "id",
      ...useTableSearch("id"),
    },

    {
      title: "Task Name",
      dataIndex: "name",
      render: (value) => <b>{value}</b>,
      ...useTableSearch("name"),
    },

    {
      title: "Resource Allotted",
      dataIndex: "resource",
      ...useTableSearch("resource"),
    },
    {
      title: "Cost",
      dataIndex: "cost_per_hour",
      render: (text, row, index) => {
        return (
          <div>
            Rs {text} /Hr ( Rs {row?.cost_per_min} /Min )
          </div>
        );
      },
    },
    {
      title: "Minutes per unit",
      dataIndex: "min_per_unit",
    },
    {
      title: "Cost per unit",
      dataIndex: "cost_per_unit",
    },
  ];

  useEffect(() => {
    getTaskList();
  }, []);

  const getTaskList = async () => {
    const { data } = await axios.get("/api/make/task/list");
    setTaskList(data);
  };

  const taskBtnClick = () => {
    setShowTaskModal(true);
  };

  const createTaskHandler = async (_obj) => {
    let error = isEmpty(_obj);

    try {
      if (error) {
        message.error(`${error}`);
        setShowTaskModal(true);
      }
      if (!error) {
        const response = await axios.post("/api/make/task/create", _obj);

        message.success("Task created sucessfully");
        setShowTaskModal(false);
      }
    } catch (error) {
      window.alert(error.message);
    }
  };

  return (
    <div>
      <div className="flex justify-end">
        <Button type="primary" onClick={taskBtnClick}>
          + New Task
        </Button>
      </div>
      <div>
        <Table columns={taskCols} dataSource={taskList} />
      </div>

      {showTaskModal && (
        <TaskModal
          visible={showTaskModal}
          onOk={async (_object) => {
            await createTaskHandler(_object);
            await getTaskList();
          }}
          onCancel={() => setShowTaskModal(false)}
        />
      )}
    </div>
  );
};

export default Tasks;
