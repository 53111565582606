import React from "react";
import { Text, Page, View, Image, Document } from "@react-pdf/renderer";
import { styles, comb } from "../../styles";
import moment from "moment";
import commaSeparatedRupees from "../../../../common/functions/commaSeparatedRupees";
import getAddress from "../../../../common/functions/getAddress";
import { roundValue } from "../../../../common/functions/roundValue";
import fallbackLogo from "../../../../assets/images/logo.png";
const GrnPdf = ({ data, headerData }) => {
  const { grn_t, grn_items, partner, po } = data;
  console.log(headerData, "headerInfo");
  console.log(data, "GRN DATA");
  const tablesReturner = () => {
    const len = data.grn_items.length;
    const ITEMS_PER_PAGE = 8;
    const MAX_ITEMS_IN_OTHER_PAGES = 15;

    if (len <= ITEMS_PER_PAGE) return <Table items={data.grn_items} />;
    else {
      let _insertedInPage = 0;

      let _firstPage = (
        <>
          <Table items={data.grn_items.slice(0, 8)} />
          <View break />
        </>
      );
      let _combinedArr = [_firstPage];
      for (let i = 8; i < len; i = i + MAX_ITEMS_IN_OTHER_PAGES) {
        let _items = data.grn_items.slice(i, i + MAX_ITEMS_IN_OTHER_PAGES);
        _insertedInPage += _items.length;
        _combinedArr.push(
          <>
            <Table items={_items} />
            {_insertedInPage >= MAX_ITEMS_IN_OTHER_PAGES && <View break />}
          </>
        );
        if (_insertedInPage >= MAX_ITEMS_IN_OTHER_PAGES) _insertedInPage = 0;
      }
      return _combinedArr;
    }
  };
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <Text
            style={[
              styles.upperCase,
              styles.bold,
              {
                marginBottom: 10,
                fontSize: 20,
                textAlign: "center",
                color: "darkgreen",
              },
            ]}
          >
            GRN Copy
          </Text>

          <InvoiceHeader grn_t={grn_t} headerData={headerData} />

          <SourceDestination partner={partner} />


          {tablesReturner()}


          <ClosingNotes/>
        </Page>
      </Document>
    </>
  );
};

const InvoiceHeader = ({ grn_t, headerData }) => {
  const { grn_id, po_id } = grn_t;
  const { GST, address, city, email, logo, name, pincode, state } = headerData;

  return (
    <>
      <View style={[styles.flex, styles.justifyBetween, { marginBottom: 10 }]}>
        {/* Top Left */}
        <View
          style={[
            styles.flexCol,
            styles.fontZeroPointNineEm,
            { maxWidth: 300 },
          ]}
        >
          <Text style={[styles.bold, styles.upperCase, { fontSize: 12 }]}>
            {name}
          </Text>
          <Text>{address}</Text>
          <Text>
            {state}, {pincode}
          </Text>
          {/* GSTIN */}
          <Text style={{ paddingTop: 5 }}>
            <Text style={styles.bold}>GSTIN / UIN: </Text>
            <Text>{GST}</Text>
          </Text>
          {/* State Name */}
          <Text>
            <Text style={styles.bold}>State Name: </Text>
            <Text>{state}</Text>
          </Text>
          {/* Email */}
          <Text>
            <Text style={styles.bold}>Email: </Text>
            <Text style={{ color: "darkblue" }}>{email}</Text>
          </Text>
        </View>

        {/* Top Right */}
        <View style={styles.flexCol}>
          <Image
            source={headerData?.logo || fallbackLogo}
            style={{ width: 60, height: 40, margin: "auto", marginBottom: 10 }}
          />
          {/* <Text>
              <Text style={styles.bold}>Creation Date:</Text>
              <Text>{moment(created_date).format("Do MMMM YYYY")}</Text>
            </Text>
            <Text>
              <Text style={styles.bold}>Print Date:</Text>
              <Text>{moment().format("Do MMMM YYYY")}</Text>
            </Text> */}
          <Text>
            <Text style={styles.bold}>GRN #: </Text>
            <Text>{grn_id}</Text>
          </Text>

          <Text>
            <Text style={styles.bold}>PO #:</Text>
            <Text>{po_id}</Text>
          </Text>
        </View>
      </View>
      <View
        style={{
          height: 1,
          backgroundColor: "grey",
          width: "100%",
          marginBottom: 20,
        }}
      />
    </>
  );
};

const SourceDestination = ({ partner }) => {
  const { name, contact, email, gstin } = partner;
  return (
    <View style={[styles.flex, { marginBottom: 10 }]}>
      <View style={[comb.sourceDestBox, { marginRight: 10 }]}>
        <Text style={comb.fromToText}>From</Text>
        <View style={[styles.paddingFive, styles.flexCol]}>
          <View>
            <Text style={[styles.bold, styles.upperCase]}>{name}</Text>
          </View>
          {/* first address */}
          {/* <View>
                <Text>{getAddress({ address1, address2, city, pincode })}</Text>
              </View> */}
          {/* GSTIN/UIN */}
          <View>
            <Text>
              <Text style={styles.bold}>GSTIN/UIN </Text>: {gstin}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>State Name</Text>:
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>email</Text>: {email}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>Phone</Text>: {contact}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export const Table = ({ items }) => {
  return (
    <View style={[styles.flexTable]}>
      <TableHeader />
      <TableData items={items} />
    </View>
  );
};

const TableHeader = () => {
  return (
    <View style={[styles.flexTableHeader, styles.flex]}>


      <View style={[styles.flexTableTd, styles.columnDescription]}>
        <Text>Goods</Text>
      </View>

      <View  style={[styles.flexTableTd, styles.columnRate]}>
        <Text>Price per UoM</Text>
      </View>

      <View style={[styles.flexTableTd, styles.columnHsn]}>
        <Text>Tax Code</Text>
      </View>

      <View style={[styles.flexTableTd, styles.columnHsn]}>
        <Text>Tax</Text>
      </View>

      <View style={[styles.flexTableTd, styles.columnQty]}><Text>Delivered Quantity</Text>
      </View>

      

      <View style={[styles.flexTableTd, styles.borderRightZero, styles.textRight]}>
        <Text>Amount</Text>
      </View>
    </View>
  );
};

const TableData = ({ items }) => {
  return (
    <>
      {items.map(
        (
          {
            name,
            product_name,
            id,
            quantity,
            uom,
            rate,
            tax,
            tax_breakup,
            amount,
            discount,

            tax_code,
          },
          i
        ) => {
          return (
            <View
              key={id}
              style={{
                flexDirection: "row",
                borderBottomWidth: 1,
                borderBottomColor: "lightgrey",
                backgroundColor: i % 2 ? "white" : "whitesmoke",
              }}
            >


              {/* Description */}
              <View
                style={[
                  styles.flexTableTd,
                  styles.flexCol,
                  styles.columnDescription,
                ]}
              >
                <Text style={styles.bold}>{name}</Text>
              </View>


              <View style={[styles.flexTableTd, styles.columnRate]}>
                <Text style={styles.textRight}>
                  {commaSeparatedRupees(rate)}
                </Text>
              </View>

              {/* HSN / SAC */}
              <View style={[styles.flexTableTd, styles.columnHsn]}>
                <Text>{tax_code}</Text>
              </View>

              <View style={[styles.flexTableTd, styles.columnHsn]}>
                <Text>{`${tax}%`}</Text>
              </View>

              {/* Qty */}
              <View style={[styles.flexTableTd, styles.columnQty]}>
                <Text style={styles.textRight}>{quantity}</Text>
              </View>

              {/* Rate */}
              {/* <View style={[styles.flexTableTd, styles.columnRate]}>
                <Text style={styles.textRight}>
                  {commaSeparatedRupees(rate)}
                </Text>
              </View> */}

              {/* GST Breakup */}
              {/* <View style={[styles.flexTableTd, styles.columnGst]}>
                  {Object.entries(tax_breakup).map(([key, value]) => {
                    return (
                      <View style={[styles.flex, styles.justifyBetween]}>
                        <Text>{`${key} @${value}%`}</Text>
                        <Text>{`${roundValue(
                          ((value || 0) / 100) * (rate * quantity)
                        )}`}</Text>
                      </View>
                    );
                  })}
                </View> */}

              {/* Discount */}
              {/* <View style={[styles.flexTableTd, styles.columnDiscount]}>
                  <Text style={styles.textRight}>{discount}%</Text>
                </View> */}

              {/* Amount */}
              <View
                style={[
                  styles.flexTableTd,
                  styles.borderRightZero,
                  styles.textRight,
                ]}
              >
                <Text>{commaSeparatedRupees(amount)}</Text>
              </View>
            </View>
          );
        }
      )}
    </>
  );
};


const ClosingNotes = () => {
    return (
      <>
        <View
          style={[
            styles.flex,
            styles.justifyCenter,
            {
              marginTop: 30,
              borderTopWidth: 1,
              borderTopColor: "lightgrey",
              paddingTop: 3,
            },
          ]}
        >
          <Text>This is a Computer Generated GRN</Text>
        </View>
      </>
    );
  };

export default GrnPdf;
