import React, { useEffect, useState } from "react";
import axios from "axios";
import { DatePicker, Button, Table, Select, Input, InputNumber } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import useTableSearch from "../../../common/hooks/useTableSearch";
import commaSeparatedRupees from "../../../common/functions/commaSeparatedRupees";

const { Option } = Select;

const ReportOfTopSellers = () => {
  const FilterBy = [
    { key: 1, label: "Quantity Of Product" },
    { key: 2, label: "Revenue generated" },
  ];
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [topSellersReport, setTopSellersReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState("Quantity Of Product");
  const [limit, setLimit] = useState(5);

  
  const getTopSellersByRevenue = async () => {
    let url = `/api/report/topSellingProductsbor?start_date=${moment(
      startDate
    ).toISOString()}&last_date=${moment(endDate).toISOString()}&limit=${limit}`;
    const { data } = await axios.get(url);
    setTopSellersReport(data);
  };

  const getTopSellersReportByQuantity = async () => {
    let url = `/api/report/topSellingProductsboq?start_date=${moment(
      startDate
    ).toISOString()}&last_date=${moment(endDate).toISOString()}&limit=${limit}`;
    const { data } = await axios.get(url);
    setTopSellersReport(data);
  };

  useEffect(() => {
    if (filterOptions === "Quantity Of Product") {
      getTopSellersReportByQuantity();
    } else {
      getTopSellersByRevenue();
    }
  }, [startDate, endDate, filterOptions, limit]);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      ...useTableSearch("name"),
    },
    {
      title: `${
        filterOptions === "Quantity Of Product" ? "Quantity" : "Revenue"
      }`,
      dataIndex: `${
        filterOptions === "Quantity Of Product"
          ? "quantity_sold"
          : "revenue_generated"
      }`,
      render: (value, record, index) => commaSeparatedRupees(value)
    },
  ];
  return (
    <div>
      <div>Filters</div>
      <div className="flex justify-between">
        <DatePicker.RangePicker
          onCalendarChange={(dates, dateStrings, range) => {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
          }}
          allowClear={false}
          disabledDate={(d) => !d || d.isAfter(moment())}
          value={[startDate, endDate]}
        />

        <div>
          <Select
            style={{ width: "12rem" }}
            placeholder="Filter by"
            defaultValue={"Quantity of Product"}
            value={filterOptions}
            onChange={(v) => setFilterOptions(v)}
          >
            {FilterBy.map(({ key, label }) => {
              return (
                <Option key={key} value={label}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </div>

        <div>
          <span>Limit : </span>
          <InputNumber
            min={1}
            defaultValue={5}
            value={limit}
            onChange={(v) => setLimit(v)}
          />
        </div>

        <div>
          <Button type="primary" style={{ marginLeft: "0.5rem" }}>
            <CSVLink data={topSellersReport} filename={"topSellers.csv"}>
              Export
            </CSVLink>
          </Button>
        </div>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <Table
          scroll={{
            x: true,
          }}
          // loading={isLoading}
          columns={columns}
          dataSource={topSellersReport}
        />
      </div>
    </div>
  );
};

export default ReportOfTopSellers;
