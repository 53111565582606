import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Table } from "antd";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";
import { skuType } from "../sales-orders/itemTypes";

const GoodsDeliveryNotes = ({ getSkus, getMaterials, skus, materials }) => {
  const [list, setList] = useState([]);

  const COL = [
    {
      title: "ID",
      dataIndex: "id",
      key: "item_id",
      sorter: (a, b) => a.id - b.id,
      ...useTableSearch("id"),
    },
    {
      title: "Item Name",
      dataIndex: "item_id",
      key: "item_id",
      render: (value, row) => {
        const isSku = row?.item_type?.toLowerCase === skuType;
        const dataSource = isSku ? skus : materials;
        const item = dataSource?.find(({ id }) => id === value);

        const key = isSku ? "sku" : "name";
        return item?.[key];
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      render: (text) => moment(text).format("DD-MMM-YY"),
      sorter: (x, y) => moment(x?.date).unix() - moment(y?.date).unix(),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, row) => text + " " + row.uom,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text) => text,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (text) => text + " %",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, row) => "Rs " + text,
    },
    // {
    //   title: "Custom",
    //   render: (text, row) => (
    //     <button onClick={() => window.alert(JSON.stringify(row))}>
    //       Click Me
    //     </button>
    //   )
    // }
  ];

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!skus.length) getSkus();
    if (!materials.length) getMaterials();
  }, []);

  const getList = async () => {
    try {
      const { data } = await axios.get("/api/sales/gdn/list");
      setList(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  return (
    <div>
      {/* <div>{JSON.stringify(list)}</div> */}
      <Table dataSource={list} columns={COL} />
    </div>
  );
};

const mapState = (state) => {
  return {
    skus: state.data.skus || [],
    materials: state.data.materials || [],
  };
};

const mapDispatch = {
  getSkus: () => getList("skus"),
  getMaterials: () => getList("materials"),
};

export default connect(mapState, mapDispatch)(GoodsDeliveryNotes);
